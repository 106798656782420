import {useEffect, useMemo, useRef, useState} from 'react';
import {Nullable} from '@hy-vee/ts-utils';

const BOUNCE_DELAY = 300;
const INITIAL_DEBOUNCED_VALUE = Symbol('INITIAL_DEBOUNCED_VALUE');

export const debounce = (func: (value: any | undefined) => void): ((value: any | undefined) => void) => {
    let timer;

    return (...args) => {
        clearTimeout(timer);

        timer = setTimeout(() => {
            func.apply(this, args);
        }, BOUNCE_DELAY);
    };
};

export const useDebouncedValue = (
    value: Nullable<any>,
    delay = BOUNCE_DELAY
): [debouncedValue: Nullable<any>, bouncing: boolean] => {
    const [debouncedValue, setDebouncedValue] = useState(INITIAL_DEBOUNCED_VALUE);
    const isBouncing = useMemo(() => value !== debouncedValue, [value, debouncedValue]);
    const timeOutHandlerRef = useRef<number>(-1);

    useEffect(() => {
        const reseTimeout = () => {
            clearTimeout(timeOutHandlerRef.current);
            timeOutHandlerRef.current = -1;
        };

        reseTimeout();
        // @ts-expect-error-next-line
        timeOutHandlerRef.current = setTimeout(() => {
            reseTimeout();
            setDebouncedValue(value);
        }, delay);

        return () => {
            reseTimeout();
        };
    }, [value, delay, timeOutHandlerRef]);

    return [debouncedValue, isBouncing];
};
