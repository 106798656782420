import Script from 'next/script';

import {useAislesOnlineRouter} from '../hooks/use-aisles-online-router';

const GTM_TRACKING_ID = 'GTM-5TL68P';

const excludedPages = ['/aisles-online/cloudflare/500'];

export const GoogleTagManagerScript = () => {
    const router = useAislesOnlineRouter();

    if (excludedPages.includes(router.pathnameWithBasePath)) {
        return null;
    }

    return (
        <Script
            dangerouslySetInnerHTML={{
                __html: `
                    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer', '${GTM_TRACKING_ID}');
                `
            }}
            data-testid="gtm-init"
            id="gtm-init"
            strategy={'afterInteractive'}
        />
    );
};
