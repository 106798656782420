export const isServerSide = () => typeof window === 'undefined';

export const getGlobalLocationPathnameWithSearch = (globalObj) => {
    if (globalObj.location) {
        const search = globalObj.location.search;

        return search ? `${globalObj.location.pathname}${search}` : globalObj.location.pathname;
    }

    return undefined;
};
