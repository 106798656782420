import {
    SELECT_MTO_DELIVERY_ADDRESS_SUCCESS,
    SELECT_MTO_DELIVERY_ADDRESS_REQUEST,
    SELECT_MTO_DELIVERY_ADDRESS_FAILURE
} from '../../action-types';
import {postMessageToIframeParent} from '../../utils/iframe-helpers';

export const selectMtoDeliveryAddress =
    ({address, deliveryAddressId}) =>
    (dispatch) => {
        const encodedSearch = encodeURIComponent(address);

        return dispatch({
            fetch: {
                onFailure: (dispatchFromMiddleware) => {
                    dispatchFromMiddleware({
                        type: SELECT_MTO_DELIVERY_ADDRESS_FAILURE
                    });
                },
                onSuccess: (dispatchFromMiddleware, getStateFromMiddleware, data) => {
                    dispatchFromMiddleware({
                        type: SELECT_MTO_DELIVERY_ADDRESS_SUCCESS
                    });
                    postMessageToIframeParent({
                        body: {
                            deliveryAddressId,
                            latitude: data.latitude,
                            longitude: data.longitude
                        },
                        message: 'delivery-address-selected'
                    });
                },
                url: `/aisles-online/api/google-api/geocode?address=${encodedSearch}`
            },
            type: SELECT_MTO_DELIVERY_ADDRESS_REQUEST
        });
    };
