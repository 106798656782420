import {INTERNAL_SERVER_ERROR} from 'http-status';

import {
    SET_CUSTOMER_CARDS_FAILURE,
    SET_CUSTOMER_CARDS_REQUEST,
    SET_CUSTOMER_CARDS_SUCCESS,
    UPDATE_CUSTOMER_CARD_SUCCESS,
    UPDATE_CUSTOMER_CARD_FAILURE,
    UPDATE_CUSTOMER_CARD_REQUEST
} from '../../action-types';

export const loadCustomerCardData = (customerCardId) => ({
    fetch: {
        onFailure: (dispatch, getState, error) => {
            dispatch({
                loadCustomerCardsError: true,
                response: error.response,
                type: SET_CUSTOMER_CARDS_FAILURE
            });
        },
        onSuccess: (dispatch, getState, data) => {
            dispatch({
                type: SET_CUSTOMER_CARDS_SUCCESS,
                ...data
            });
        },
        url: customerCardId
            ? `/aisles-online/api/payment-methods?customerCardId=${customerCardId}`
            : '/aisles-online/api/payment-methods'
    },
    type: SET_CUSTOMER_CARDS_REQUEST
});

export const updateCustomerCard = (customerCardId, values) => (dispatch, getState) => {
    const {customerId} = getState().customer;
    const getErrorMessage = (response) =>
        response.statusCode === INTERNAL_SERVER_ERROR
            ? 'There was an error updating your card. Please try again.'
            : response.message;

    return dispatch({
        fetch: {
            onFailure: (dispatchFromMiddleware, getStateFromMiddleware, error) => {
                error.response.json().then((response) =>
                    dispatchFromMiddleware({
                        customerCardError: getErrorMessage(response),
                        type: UPDATE_CUSTOMER_CARD_FAILURE
                    })
                );
            },
            onSuccess: (dispatchFromMiddleware, getStateFromMiddleware, data) => {
                dispatchFromMiddleware({
                    type: UPDATE_CUSTOMER_CARD_SUCCESS,
                    ...data
                });
            },
            options: {
                body: JSON.stringify(values),
                method: 'PUT'
            },
            url: `/aisles-online/api/customer/${customerId}/customer-card/${customerCardId}`
        },
        type: UPDATE_CUSTOMER_CARD_REQUEST
    });
};
