import {
    differenceInMonths,
    getMinutes,
    format,
    isAfter,
    isBefore,
    isToday,
    isTomorrow,
    lightFormat,
    parseISO
} from 'date-fns';
import {utcToZonedTime} from 'date-fns-tz';

import {MODIFICATION_TIME_BEFORE_FULFILLMENT_HOURS} from '../../enums/order-modification-constants';

const timezone = 'America/Chicago';

export const formatDate = (date) => format(new Date(date ? date : Date.now()), 'MM/dd/yyyy');

export const getFormattedTime = (time) => {
    if (getMinutes(new Date(time)) !== 0) {
        return format(utcToZonedTime(new Date(time), timezone), 'h:mm aaa');
    }

    return format(utcToZonedTime(new Date(time), timezone), 'h aaa');
};

export const getFormattedTimeWithMinutes = (time, options) => {
    if (options && options.uppercaseAmPm) {
        return format(utcToZonedTime(new Date(time), timezone), 'h:mm a');
    }

    return format(utcToZonedTime(new Date(time), timezone), 'h:mm aaa');
};

export const getFormattedDateWithNoTime = (date) => {
    return format(utcToZonedTime(new Date(date), timezone), 'EEEE MMMM d, yyyy');
};

export const getFormattedTimeWithDay = (date) => format(utcToZonedTime(new Date(date), timezone), 'EEEE, MMM dd');

export const getFormattedDateDay = (date) => {
    return format(utcToZonedTime(new Date(date), timezone), 'dd');
};

export const formatMonth = (date) => {
    return format(utcToZonedTime(new Date(date), timezone), 'MMM');
};

export const getFormattedMonthYear = (date) => {
    return format(utcToZonedTime(new Date(date), timezone), 'MMMM yyyy');
};

export const hasDateRangeExpired = (beginDate, endDate) => {
    const currentDate = parseISO(lightFormat(new Date(Date.now()), 'yyyy-MM-dd'));

    return isBefore(currentDate, new Date(beginDate)) || isAfter(currentDate, new Date(endDate));
};

export const getFormattedCardExpirationDate = (card) =>
    `${`0${card.expirationMonth}`.slice(-2)}/${card.expirationYear}`;

const getDayOfWeekText = (date) => {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    return days[date.getDay()];
};

const getTodayOrTomorrowText = (date) => {
    if (isToday(date)) {
        return 'today';
    } else if (isTomorrow(date)) {
        return 'tomorrow';
    }

    return '';
};

export const getFormattedOrderModificationExpirationTime = (fulfillmentWindowStart) => {
    const date = new Date(fulfillmentWindowStart);
    const zonedTime = utcToZonedTime(
        date.setHours(date.getHours() - MODIFICATION_TIME_BEFORE_FULFILLMENT_HOURS),
        timezone
    );
    const todayOrTomorrowText = getTodayOrTomorrowText(zonedTime);

    const formattedDate = format(zonedTime, 'M/d');
    const formattedTime = format(zonedTime, 'h:mm a');

    return todayOrTomorrowText
        ? `${todayOrTomorrowText} ${formattedDate} at ${formattedTime}`
        : `${getDayOfWeekText(zonedTime)} ${formattedDate} at ${formattedTime}`;
};

export const isCreditCardExpired = (monthYearExpirationDate) => {
    const month = parseInt(monthYearExpirationDate.slice(0, 2)) - 1;
    const year = monthYearExpirationDate.slice(3);

    return differenceInMonths(new Date(), new Date(year, month)) > 0;
};
