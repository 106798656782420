import {INTERNAL_SERVER_ERROR, LOCKED} from 'http-status';

import {
    DELETE_CARD_FAILURE,
    DELETE_CARD_REQUEST,
    DELETE_CARD_SUCCESS,
    SET_DELETE_CARD_ERROR,
    SET_CUSTOMER_CARDS,
    CLEAR_DELETE_CARD_ERROR
} from '../../action-types';
import {postMessageToIframeParent} from '../../utils/iframe-helpers';

export const clearDeleteCardError = () => ({
    type: CLEAR_DELETE_CARD_ERROR
});

export const deleteCard = (customerCardId, toggleActiveModal) => ({
    fetch: {
        onFailure: (dispatch, _, error) => {
            error.response.json().then((response) => {
                if (response.statusCode === INTERNAL_SERVER_ERROR) {
                    dispatch({
                        deleteCardError: {
                            message: 'An error has occurred. Please try again.'
                        },
                        type: SET_DELETE_CARD_ERROR
                    });
                }

                if (response.statusCode === LOCKED) {
                    dispatch({
                        deleteCardError: {
                            message:
                                "We can't delete this card right now because there is a pending order. Please try again after your order is complete."
                        },
                        type: SET_DELETE_CARD_ERROR
                    });
                }

                dispatch({
                    response,
                    type: DELETE_CARD_FAILURE
                });
            });
        },
        onSuccess: (dispatch, _, data) => {
            dispatch({
                type: DELETE_CARD_SUCCESS
            });
            dispatch({
                type: SET_CUSTOMER_CARDS,
                ...data
            });
            postMessageToIframeParent('card-deleted');
            toggleActiveModal('DELETE_CARD');
        },
        options: {
            method: 'DELETE'
        },
        url: `/aisles-online/api/payment-methods/${customerCardId}`
    },
    type: DELETE_CARD_REQUEST
});
