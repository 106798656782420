import {ApolloClient, defaultDataIdFromObject, from, InMemoryCache, setLogVerbosity} from '@apollo/client';
import getConfig from 'next/config';

import {isServerSide} from '../lib/env';

import {getHeadersMiddleware, getHttpLink} from './graphql-client-utils';

let apolloClient;

const verboseLoggingEnabled = () => {
    return getConfig().publicRuntimeConfig.apolloVerboseLogging;
};

const usingLegacyCache = (object, context) => {
    if (verboseLoggingEnabled()) {
        console.warn(
            'WARNING: Using old apollo cache, please add type and id to typePolicies in https://github.hy-vee.cloud/web-and-mobile/aisles-online/blob/master/client/graphql/graphql-client.js#L39',
            object,
            context
        );
    }
};

interface SearchResult {
    correctedQuery: string | null;
    pageInfo: {
        hasNextPage: boolean;
        page: number;
        pageSize: number;
        totalCount: number;
        totalPages: number;
    };
    responseId: string | null;
    searchFilters: any[];
    searchProducts: any[] | null;
}

const createClient = (initialState = {}, ssrContext = null) => {
    return new ApolloClient({
        cache: new InMemoryCache({
            /**
             * Avoid adding to this method.
             * It is the legacy way of doing caching in Apollo Client.
             * Apollo has marked it as depreciated and it will be removed in future versions.
             * Use typePolicies instead.
             */
            dataIdFromObject: (object, context) => {
                if (object[`${object.__typename}Id`]) {
                    usingLegacyCache(object, context);

                    return `${object.__typename}:${object[`${object.__typename}Id`]}`;
                }

                return defaultDataIdFromObject(object);
            },
            typePolicies: {
                aislesOnlinePromotion: {
                    keyFields: ['aislesOnlinePromotionId']
                },
                allCmsSharedBrandLandingPage: {
                    keyFields: ['name']
                },
                banner: {
                    keyFields: ['bannerId']
                },
                cart: {
                    fields: {
                        cartItems: {
                            merge: (_, incoming) => incoming
                        }
                    },
                    keyFields: ['cartId']
                },
                cartItem: {
                    keyFields: ['cartItemId']
                },
                cartPromotion: {
                    keyFields: ['cartPromotionId']
                },
                category: {
                    keyFields: ['categoryId']
                },
                ClippedCouponV4: {
                    fields: {
                        couponId: {
                            merge: false
                        }
                    },
                    keyFields: ['couponId']
                },
                CouponV4: {
                    keyFields: ['couponId']
                },
                customer: {
                    keyFields: ['customerId']
                },
                deliveryAddress: {
                    keyFields: ['deliveryAddressId']
                },
                department: {
                    keyFields: ['departmentId']
                },
                departmentGroup: {
                    keyFields: ['departmentGroupId']
                },
                fuelSaverCard: {
                    merge: true
                },
                fulfillmentLocation: {
                    keyFields: ['fulfillmentLocationId']
                },
                fulfillmentTime: {
                    keyFields: ['fulfillmentScheduleId']
                },
                Item: {
                    keyFields: ['itemId']
                },
                list: {
                    fields: {
                        listItems: {
                            merge: (_, incoming) => incoming
                        }
                    },
                    keyFields: ['listId']
                },
                listItem: {
                    keyFields: ['listItemId']
                },
                Location: {
                    keyFields: ['locationId']
                },
                madeToOrderCart: {
                    keyFields: ['cartId']
                },
                mtoOrder: {
                    keyFields: ['mtoOrderId']
                },
                order: {
                    keyFields: ['orderId']
                },
                orderItem: {
                    keyFields: ['orderItemId']
                },
                pickupLocation: {
                    keyFields: ['pickupLocationId']
                },
                product: {
                    keyFields: ['productId']
                },
                productGroup: {
                    keyFields: ['productGroupId']
                },
                productLocker: {
                    keyFields: ['productLockerId']
                },
                Query: {
                    fields: {
                        buyAgainProducts: {
                            keyArgs: ['page', 'input', ['sortDirection', 'searchTerm']]
                        },
                        cartItem: (existing, {args, toReference}) =>
                            existing ||
                            toReference({
                                __typename: 'cartItem',
                                cartItemId: String(args?.cartItemId)
                            }),
                        carts: {
                            keyArgs: (args) =>
                                args?.customerId
                                    ? `carts({"customerId":"${args?.customerId}","where":${JSON.stringify(
                                          args?.where
                                      )}})`
                                    : `carts({"where":${JSON.stringify(args?.where)}})`,
                            merge: false
                        },
                        couponV4: (existing, {args, toReference}) =>
                            existing ||
                            toReference({
                                __typename: 'CouponV4',
                                couponId: String(args?.couponId)
                            }),
                        product: (existing, {args, toReference}) =>
                            existing ||
                            toReference({
                                __typename: 'product',
                                productId: String(args?.productId)
                            }),
                        searchProductsResultV2: {
                            keyArgs: ['searchInput', ['searchTerm']],
                            merge: (existing: SearchResult, incoming: SearchResult, {args}) => {
                                if (!existing || !args || args.searchInput.pageInfoInput.pageSize > 50) {
                                    return incoming;
                                }

                                const exisitingProducts = existing.searchProducts ?? [];
                                const incomingProducts = incoming.searchProducts ?? [];

                                return {
                                    ...incoming,
                                    searchProducts: [...exisitingProducts, ...incomingProducts]
                                };
                            }
                        },
                        storeProduct: (existing, {args, toReference}) =>
                            existing ||
                            toReference({
                                __typename: 'storeProduct',
                                storeProductId: String(args?.storeProductId)
                            }),
                        substitutionProduct: (existing, {args, toReference}) =>
                            existing ||
                            toReference({
                                __typename: 'substitutionProduct',
                                substitutionProductId: String(args?.substitutionProductId)
                            }),
                        unleashFeatureToggles: {
                            merge: (existing = [], incoming = []) => [...existing, ...incoming],
                            read: (existing, {args, toReference}) => {
                                if (!existing) {
                                    return undefined;
                                }

                                const features = new Set(
                                    args?.featureNames.map(
                                        (featureName) =>
                                            toReference({
                                                __typename: 'UnleashFeatureToggle',
                                                featureName,
                                                properties: {
                                                    storeCode: String(args.properties.storeCode)
                                                }
                                            })?.__ref
                                    )
                                );

                                const exisitingFeatureRefs = existing.filter((featureJson) =>
                                    features.has(featureJson.__ref)
                                );

                                return exisitingFeatureRefs.length ? exisitingFeatureRefs : undefined;
                            }
                        }
                    }
                },
                SearchFilter: {
                    fields: {
                        searchFilterOptions: {
                            merge: (_, incoming) => incoming
                        }
                    }
                },
                SearchProduct: {
                    keyFields: ['productId']
                },
                store: {
                    keyFields: ['storeId']
                },
                storeProduct: {
                    keyFields: ['storeProductId']
                },
                subcategory: {
                    keyFields: ['subcategoryId']
                },
                substitutionProduct: {
                    keyFields: ['substitutionProductId']
                },
                UnleashFeatureToggle: {
                    keyFields: ['featureName', 'properties', ['storeCode']]
                },
                userSelectedSubstitution: {
                    keyFields: ['userSelectedSubstitutionId']
                }
            }
        }).restore(initialState),
        connectToDevTools: true,
        link: from([getHeadersMiddleware(ssrContext), getHttpLink()]),
        name: 'aisles-online-web',
        ssrMode: Boolean(ssrContext)
    });
};

export const graphqlClient = (initialState?: any, ssrContext?: any) => {
    if (isServerSide()) {
        return createClient(initialState, ssrContext);
    }

    if (!apolloClient || initialState) {
        apolloClient = createClient(initialState);
        setLogVerbosity(getConfig().publicRuntimeConfig.apolloVerboseLogging ? 'log' : 'error');

        return apolloClient;
    }

    return apolloClient;
};
