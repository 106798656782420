import {SET_NO_SUBS_AVAILABLE} from '../../action-types';

export const setNoSubsAvailable = (cartItemId) => (dispatch, getState) => {
    const existingCartItemsWithNoSubs = getState().checkoutSubstitutions.cartItemsWithNoSubsAvailable;

    return dispatch({
        cartItemsWithNoSubsAvailable: [...existingCartItemsWithNoSubs, cartItemId],
        type: SET_NO_SUBS_AVAILABLE
    });
};
