import {isServerSide} from '../lib/env';

const hostsToEnv = {
    'dev.hy-vee.com': 'dev',
    'hy-vee.com': 'prod',
    'www.hy-vee.com': 'prod'
};

export const env = () => {
    if (isServerSide()) {
        return process.env.NODE_ENV;
    }

    return hostsToEnv[window.location.hostname] || 'localhost';
};
