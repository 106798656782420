import * as Sentry from '@sentry/browser';

import {env} from './environment';

const isDevelopment = () => env() === 'localhost';

export const initializeSentry = () => {
    if (!isDevelopment()) {
        Sentry.init({
            dsn: 'https://0f4f914fbc0947038c8e6fa95f2a2c56@sentry.io/1295420',
            environment: env(),
            release: process.env.GIT_SHA,
            whitelistUrls: ['dev.hy-vee.com/aisles-online', 'hy-vee.com/aisles-online']
        });
    }
};
