import {
    ALTERNATIVE_PAYMENT_PATH,
    CHECKOUT_SUBSTITUTIONS_PATH,
    GROCERY_OPTIONS_PATH,
    NEW_CART_PATH
} from 'client/enums/rerouting-paths';

import {isServerSide} from '../lib/env';

const getAppUrl = () => {
    if (isServerSide()) {
        return 'http://localhost:5555';
    }

    return window.location.origin;
};

const getPathFromUrl = (url) => {
    return url.split('?')[0];
};

// Function used for checkout flow
const getPreviousPath = (currentUrl: string) => {
    const {pathname, search} = new URL(currentUrl);

    switch (`${pathname}${search}`) {
        case `${GROCERY_OPTIONS_PATH}?checkoutMode=1`:
            return NEW_CART_PATH;

        case `${GROCERY_OPTIONS_PATH}?checkoutMode=3`:
            return NEW_CART_PATH;

        case `${CHECKOUT_SUBSTITUTIONS_PATH}?checkoutMode=1`:
            return `${GROCERY_OPTIONS_PATH}?checkoutMode=1`;

        case `${CHECKOUT_SUBSTITUTIONS_PATH}?checkoutMode=3`:
            return `${GROCERY_OPTIONS_PATH}?checkoutMode=3`;

        case `${ALTERNATIVE_PAYMENT_PATH}?iframe=true&checkoutMode=1&orderOrigin=1`:
            return `${CHECKOUT_SUBSTITUTIONS_PATH}?checkoutMode=1`;

        case `${ALTERNATIVE_PAYMENT_PATH}?iframe=true&checkoutMode=3&orderOrigin=1`:
            return `${CHECKOUT_SUBSTITUTIONS_PATH}?checkoutMode=3`;

        case `${ALTERNATIVE_PAYMENT_PATH}?iframe=true`:
            return NEW_CART_PATH;

        default:
            return null;
    }
};

const signUpUri = (uri) => {
    if (uri.includes('lcl')) {
        return uri.replace('lcl', 'dev');
    }

    return uri;
};

export {getAppUrl, getPathFromUrl, signUpUri, getPreviousPath};
