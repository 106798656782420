import {SET_CUSTOMER, SET_STORE} from '../../action-types';

export const setCustomerAndStoreData =
    ({customer, store}) =>
    (dispatch) => {
        dispatch({
            customer: {
                ...customer,
                customerUuid: customer.customerUuid.toLowerCase()
            },
            type: SET_CUSTOMER
        });

        dispatch({
            store,
            type: SET_STORE
        });
    };
