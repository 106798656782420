import {gql} from '@apollo/client';

export const customerFragment = gql`
    fragment ICustomerFragment on customer {
        customerId
        customerUuid
        username
        firstName
        lastName
        fuelSaverCardUuid
        fuelSaverCard {
            fuelSaverCardUuid
            fuelSaverCardNumber
        }
        preferredStoreId
    }
`;

export const getCustomerData = gql`
    query GetCustomerData($customerUuid: String!) {
        customer(customerUuid: $customerUuid) {
            ...ICustomerFragment
        }
    }

    ${customerFragment}
`;

export const getCustomerAndStoreData = gql`
    query getCustomerAndStoreData($customerUuid: String!) {
        customer(customerUuid: $customerUuid) {
            ...ICustomerFragment
            store {
                storeId
                name
                phone
                address
                city
                state
                zip
                latitude
                longitude
                legacyStoreId
                nickname
            }
        }
    }

    ${customerFragment}
`;

export const getCustomerAndCartStoreData = gql`
    query getCustomerAndCartStoreData($customerUuid: String!, $customerId: Int!) {
        customer(customerUuid: $customerUuid) {
            ...ICustomerFragment
        }
        carts(customerId: $customerId, where: {isActive: true}) {
            cartId
            store {
                storeId
                name
                phone
                address
                city
                state
                zip
                latitude
                longitude
                legacyStoreId
                nickname
            }
        }
    }

    ${customerFragment}
`;

export const getCustomerEndUserLicenseAgreements = gql`
    query getCustomerAndEndUserLicenseAgreements($customerId: Int!) {
        acceptedEndUserLicenseAgreements(customerId: $customerId) {
            endUserLicenseAgreementId
        }
        endUserLicenseAgreements(where: {agreementType: TERMS_AND_CONDITIONS, isActive: true}) {
            endUserLicenseAgreementId
            agreementType
            agreementUrl
            description
            isActive
        }
    }
`;

export const getUnleashFeatures = gql`
    query GetUnleashFeatureToggles($featureNames: [String!]!, $properties: UnleashProperties) {
        unleashFeatureToggles(featureNames: $featureNames, properties: $properties) {
            active
            featureName
            properties {
                storeCode
            }
        }
    }
`;
