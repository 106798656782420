import {addHours, isBefore} from 'date-fns';

import {GROCERY, MADE_TO_ORDER} from '../enums/new-checkout-types';
import * as checkoutModeEnum from '../enums/checkout-types';
import {MODIFICATION_TIME_BEFORE_FULFILLMENT_HOURS} from '../enums/order-modification-constants';

import {getFormattedOrderModificationExpirationTime} from './view-helpers/date-time-view-helpers';

export const getCheckoutTypeParamsFromEnum = (checkoutTypeEnum) => {
    const checkoutTypes = {
        [GROCERY]: 'isGrocery',
        [MADE_TO_ORDER]: 'isMadeToOrder'
    };

    return checkoutTypes[checkoutTypeEnum];
};

export const getQueryParamsFromCheckoutTypes = (checkoutTypes) => {
    const queryParams = new URLSearchParams();

    checkoutTypes.forEach((checkoutType) => queryParams.append(getCheckoutTypeParamsFromEnum(checkoutType), true));

    return queryParams.toString();
};

export const getCheckoutTypesQueryParams = (query) => {
    const checkoutTypes = [];

    if (query.isGrocery) {
        checkoutTypes.push(GROCERY);
    }

    if (query.isMadeToOrder) {
        checkoutTypes.push(MADE_TO_ORDER);
    }

    return checkoutTypes;
};

export const checkoutTypeExistsAndContains = (checkoutTypes, checkoutType) => {
    return Boolean(checkoutTypes.length && checkoutTypes.includes(checkoutType));
};

export const checkoutTypeExistsAndDoesNotContain = (checkoutTypes, checkoutType) => {
    return Boolean(checkoutTypes.length && !checkoutTypes.includes(checkoutType));
};

export const checkoutTypeIsOnly = (checkoutTypes, checkoutType) => {
    return Boolean(checkoutTypes.length === 1 && checkoutTypes.includes(checkoutType));
};

export const getMappedCheckoutMode = (checkoutMode, checkoutTypes) => {
    if (checkoutMode) {
        return checkoutMode;
    }

    if (
        checkoutTypeExistsAndContains(checkoutTypes, GROCERY) &&
        checkoutTypeExistsAndContains(checkoutTypes, MADE_TO_ORDER)
    ) {
        return checkoutModeEnum.COMBINED;
    }

    if (checkoutTypeExistsAndContains(checkoutTypes, GROCERY)) {
        return checkoutModeEnum.GROCERY;
    }

    return checkoutModeEnum.MADE_TO_ORDER;
};

export const getCheckoutOrderUpdateDisclaimer = (checkoutTypes, fulfillmentWindowStart) => {
    const checkoutTypesIncludesAll = (arr) => arr.every((item) => checkoutTypes.includes(item));
    const fulfillmentWindowStartWithinFourHours = isBefore(
        new Date(fulfillmentWindowStart),
        addHours(Date.now(), MODIFICATION_TIME_BEFORE_FULFILLMENT_HOURS)
    );

    if (fulfillmentWindowStartWithinFourHours) {
        return "This order can't be updated once placed.";
    }

    if (checkoutTypesIncludesAll([GROCERY, MADE_TO_ORDER])) {
        return "The Made to Order portion of your order can't be updated once placed.";
    }

    if (checkoutTypes.includes(GROCERY)) {
        const orderModificationExpirationTime = getFormattedOrderModificationExpirationTime(fulfillmentWindowStart);

        return `This order can be updated until ${orderModificationExpirationTime}.`;
    }

    return "This order can't be updated once placed.";
};
