const scriptPromises = {};

export const loadScript = (scriptUrl) => {
    if (!scriptPromises.hasOwnProperty(scriptUrl) && process.browser) {
        scriptPromises[scriptUrl] = new Promise((resolve) => {
            const script = document.createElement('script');

            script.src = scriptUrl;

            document.body.append(script);
            script.addEventListener('load', resolve);
        });
    }

    return scriptPromises[scriptUrl];
};
