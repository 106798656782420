import {split, ApolloLink, HttpLink} from '@apollo/client';

import {TWO_LEGGED_AUTH_TYPE} from '../enums/auth-types';
import {getAppUrl} from '../utils/url-utils';

import graphqlFetch from './graphql-fetch-service';

export const getHeadersMiddleware = (ssrContext?: any) => {
    const cookie = !ssrContext ? {} : {cookie: ssrContext.req.headers.cookie};

    return new ApolloLink((operation, forward) => {
        operation.setContext(({headers = {}}) => ({
            headers: {
                ...headers,
                ...cookie
            }
        }));

        return forward(operation);
    });
};

export const getHttpLink = () => {
    const appUri = getAppUrl();
    const getAbsoluteUrl = (url) => `${appUri}${url}`;

    const httpOptions = {
        credentials: 'same-origin',
        fetch: graphqlFetch
    };

    return split(
        (operation) => operation.variables.authType === TWO_LEGGED_AUTH_TYPE,
        new HttpLink({
            ...httpOptions,
            uri: (operation) => getAbsoluteUrl(`/aisles-online/api/graphql/two-legged/${operation.operationName}`)
        }),
        new HttpLink({
            ...httpOptions,
            uri: (operation) => getAbsoluteUrl(`/aisles-online/api/graphql/three-legged/${operation.operationName}`)
        })
    );
};

/*
 * In order to create a GraphQl query variables object that requires customerId we need to supply
 * a customerId. Since the backend will append the customer id for us we don't need to set the actual
 * value.
 */
export const EMPTY_CUSTOMER_ID = 0;
