import queryString from 'query-string';

import {assignParentUrl} from './iframe-helpers';

const getLegacyLogoutUrl = () => '/account/login.aspx?logout=1';
const COM_LOGIN_PATH = '/account/login.aspx';
const LOGIN_PATH = '/aisles-online/login';
const LOGOUT_PATH = '/aisles-online/logout';

const returnUrl = (pathWithQuery) =>
    queryString.stringify({
        redirect: pathWithQuery
    });

export const getRelativeLoginUrlWithRedirect = (path) => `/login?${returnUrl(path)}`;

export const getLoginUrlWithRedirect = (path) => `${LOGIN_PATH}?${returnUrl(path)}`;

export const redirectToLogin = (relativeReturnUrl) => assignParentUrl(getLoginUrlWithRedirect(relativeReturnUrl));

export const getLogoutUrl = () => `${LOGOUT_PATH}?${returnUrl(getLegacyLogoutUrl())}`;

export const getComLoginUrlWithRedirect = (retUrl) => `${COM_LOGIN_PATH}?${queryString.stringify({retUrl})}`;
