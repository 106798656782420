import {useContext} from 'react';

import UserDetailsContext from './context';

const useUserDetails = () => {
    const userDetailsContext = useContext(UserDetailsContext);

    if (!userDetailsContext) {
        throw new Error('User Details Hooks must be inside the User Details Provider');
    }

    return userDetailsContext;
};

export {useUserDetails};
