import {gql} from '@apollo/client';

const mtoOrderAddressFragment = gql`
    fragment IMtoAddress on mtoOrderAddress {
        mtoOrderAddressId
        customerUuid
        addressType
        addressOne
        addressTwo
        firstName
        lastName
        city
        state
        phoneNumber
        zip
    }
`;

const mtoStoreFragment = gql`
    fragment IMtoStore on store {
        storeId
        name
        phone
        address
        city
        state
        zip
        latitude
        longitude
        legacyStoreId
        nickname
    }
`;

const mtoCategoryFragment = gql`
    fragment IMtoCategory on mtoCategory {
        mtoCategoryId
        name
        description
        fulfillmentTypes
    }
`;

const mtoProductFragment = gql`
    ${mtoCategoryFragment}
    fragment IMtoProduct on madeToOrderProduct {
        productId
        categoryId
        departmentId
        price
        name
        madeToOrderProductImages {
            productImageId
            productId
            size
            filename
        }
        mtoCategoryId
        mtoCategory {
            ...IMtoCategory
            parentCategoryId
            parentCategory {
                ...IMtoCategory
            }
        }
    }
`;

export const getMadeToOrderCart = gql`
    query GetMadeToOrderCart($cartId: String!) {
        madeToOrderCart(cartId: $cartId) {
            cartId
            legacyCartId
            instructions
            createdDate
            madeToOrderCartItems {
                cartItemId
                cartId
                productId
                quantity
                storeId
                fulfillmentFee
                fulfillmentType
                fulfillmentWindowStart
                fulfillmentWindowEnd
                tax
                parentCartItemId
                price
                taxExemptAmount
                madeToOrderCartItemModifiers {
                    cartItemId
                    modifierId
                    displayText
                    cost
                    modifier {
                        modifierId
                        name
                        modifierGroup {
                            groupDisplayName
                            modifierGroupOrder
                        }
                    }
                }
                madeToOrderProduct {
                    productId
                    categoryId
                    departmentId
                    price
                    name
                    madeToOrderProductImages {
                        productImageId
                        productId
                        size
                        filename
                    }
                }
            }
        }
    }
`;
export const getMadeToOrderCartItemsForAddOnRemovalModal = gql`
    query GetMadeToOrderCartItemsForAddOnRemovalModal($cartId: String!) {
        madeToOrderCart(cartId: $cartId) {
            madeToOrderCartItems {
                cartItemId
                parentCartItemId
                madeToOrderProduct {
                    name
                }
            }
        }
    }
`;

export const getMtoOrderForOrderDetailsQuery = gql`
    ${mtoStoreFragment}
    ${mtoProductFragment}
    ${mtoOrderAddressFragment}
    query getMtoOrderForOrderDetailsQuery($mtoOrderId: String!) {
        mtoOrder(mtoOrderId: $mtoOrderId) {
            mtoOrderId
            mtoOrderNumber
            customerUuid
            date
            orderTotal
            tax
            serviceFee
            giftCardAmount
            mtoBillingAddressId
            mtoBillingAddress {
                addressOne
                addressTwo
                addressType
                city
                customerUuid
                firstName
                lastName
                mtoOrderAddressId
                phoneNumber
                state
                zip
            }
            mtoOrderItems {
                mtoOrderItemId
                cartItemId
                cost
                fulfillmentStatus
                fulfillmentType
                fulfillmentWindowStart
                fulfillmentWindowEnd
                mtoDeliveryAddressId
                mtoDeliveryAddress {
                    ...IMtoAddress
                }
                madeToOrderProduct {
                    ...IMtoProduct
                }
                productId
                quantity
                storeId
                store {
                    ...IMtoStore
                }
                tax
            }
            paymentInformation {
                cardLastFour
                expirationMonth
                expirationYear
                issuer
                nameOnCard
            }
        }
    }
`;

export const getMtoOrderItemModifiers = gql`
    query GetMtoOrderItemModifiers($mtoOrderItemId: Int!) {
        mtoOrderItemModifiers(mtoOrderItemId: $mtoOrderItemId) {
            mtoOrderItemId
            modifierId
            modifier {
                modifierId
                name
            }
            displayText
            cost
        }
    }
`;
