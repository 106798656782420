import {
    SET_STORE_SELECTION_PICKUP_PAGE_DATA_SUCCESS,
    SET_STORE_SELECTION_PICKUP_PAGE_DATA_REQUEST,
    SET_STORE_SELECTION_PICKUP_PAGE_DATA_FAILURE
} from '../../action-types';

export const loadStoreSelectionPickupPageData = () => (dispatch, getState) => {
    const carts = getState().storeSelection.carts;

    return dispatch({
        fetch: {
            onFailure: (dispatchFromMiddleWare, getStateFromMiddleware, error) => {
                dispatchFromMiddleWare({
                    response: error.response,
                    type: SET_STORE_SELECTION_PICKUP_PAGE_DATA_FAILURE
                });
            },
            onSuccess: (dispatchFromMiddleWare, getStateFromMiddleware, data) => {
                dispatchFromMiddleWare({
                    type: SET_STORE_SELECTION_PICKUP_PAGE_DATA_SUCCESS,
                    ...data
                });
            },
            url: `/aisles-online/api/store-selection/pickup?getCarts=${!carts.length}`
        },
        type: SET_STORE_SELECTION_PICKUP_PAGE_DATA_REQUEST
    });
};
