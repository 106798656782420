import * as Sentry from '@sentry/browser';

export const postMessageToIframeParent = (message, attempts = 0) => {
    if (window.parentIFrame) {
        window.parentIFrame.sendMessage(message);
    } else {
        const currentAttempts = attempts + 1;

        if (currentAttempts === 100) {
            console.error('Unable to postMessage: parentIframe not set after 25 seconds');
            Sentry.captureException('Unable to postMessage: parentIframe not set after 25 seconds');
        } else {
            setTimeout(() => {
                postMessageToIframeParent(message, currentAttempts);
            }, 250);
        }
    }
};

export const postMessageToIframeChild = (id, message) => {
    const iframe = document.querySelector(id);

    iframe.contentWindow.postMessage(message, iframe.src);
};

export const getReturnUrlFromParent = () => {
    let returnUrl;

    try {
        returnUrl = encodeURIComponent(parent.location.pathname + parent.location.search);
    } catch (error) {
        returnUrl = encodeURIComponent(window.location.pathname + window.location.search);
    }

    return returnUrl;
};

export const getUrl = () => {
    try {
        return parent.location.href;
    } catch (error) {
        return window.location.href;
    }
};

export const assignParentUrl = (url) => {
    try {
        parent.location.assign(url);
    } catch (error) {
        window.location.assign(url);
    }
};

export const replaceStateParentUrl = (searchParameters) => {
    try {
        parent.history.replaceState(parent.history.state, '', searchParameters);
    } catch (error) {
        window.history.replaceState(window.history.state, '', searchParameters);
    }
};
