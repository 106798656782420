enum GTMEvent {
    EvolyticsClick = 'select_promotion',
    EvolyticsImpression = 'view_promotion',
    PromoClick = 'promoClick',
    PromoImpressions = 'promoImpressions',
    ProductImpressions = 'productImpressions',
    GAEvent = 'gaEvent',
    IframePageView = 'iframePageView',
    ProductClick = 'productClick',
    Purchase = 'purchase',
    AddToCart = 'addToCart',
    TrackCheckoutStep = 'trackCheckoutStep',
    TrackPurchase = 'trackPurchase',
    TrackPurchaseNoStore = 'trackPurchaseNoStore',
    CartFlyoutScroll = 'cartFlyoutScroll',
    AdPageLoad = 'ad_page_load',
    ProductCardSimple = 'product_card_simple',
    Viewed = 'viewed',
    Clicked = 'clicked',
    BrandLandingPage = 'brand_landing_page'
}

export default GTMEvent;
