import {createGlobalStyle} from 'styled-components';

export const AislesOnlineGlobalStyle = createGlobalStyle`
    button:hover:disabled {
        cursor: not-allowed;
    }

    footer {
        flex-shrink: 0;
    }

    main {
        display: flex;
        flex-direction: column;
    }

   strong {
       font-weight: 500;
   }

   em {
        font-style: italic;
   }

   html, body {
        height: 100%;
        min-height: 100vh;
    }

    #__next {
        height: 100%;
    }

    .wpwl-form {
        margin: 0px auto !important;
    }

    #payPalHostedButton {
        width: 100%;
        padding: 8px 0px;
    }
`;
