import getConfig from 'next/config';

import {loadScript} from './script-loading-service';

interface ICitrusInstance {
    reportClick: (any) => void;
    reportImpression: (any) => void;
}

let citrusInstance: ICitrusInstance | undefined;

export const getCitrusInstance: () => Promise<ICitrusInstance> = async () => {
    if (!citrusInstance) {
        // see https://developers.citrusad.com/docs/javascript-sdk
        await loadScript('https://assets.citrusad.net/citrusjs/0.3.0/citrus.js');

        const {publicRuntimeConfig} = getConfig();
        const citrusUrl = publicRuntimeConfig.citrus.url;

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        citrusInstance = global.CitrusAd.init(citrusUrl, {
            disableTracking: false
        }) as ICitrusInstance;
    }

    return citrusInstance;
};
