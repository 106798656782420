import {graphqlClient} from '../../graphql-client';
import {deleteMTOCartItemMutation, updateMTOCartItemMutation} from '../mto-mutations';
import {updateMTOCartCacheAfterDelete, updateMTOCartCacheAfterUpdate} from '../mutation-helpers/mto-mutation-helpers';
import {TWO_LEGGED_AUTH_TYPE} from '../../../enums/auth-types';

export const deleteMTOCartItemAction = (cartId, cartItemId) =>
    graphqlClient().mutate({
        mutation: deleteMTOCartItemMutation,
        optimisticResponse: {
            __typename: 'Mutation',
            deleteMadeToOrderCartItem: cartItemId
        },
        update: (cache, {data}) => updateMTOCartCacheAfterDelete(cache, cartId, data.deleteMadeToOrderCartItem),
        variables: {
            authType: TWO_LEGGED_AUTH_TYPE,
            cartId,
            cartItemId: Number(cartItemId)
        }
    });

export const updateMTOCartItemAction = ({cartId, cartItemId, ...cartItemInput}) =>
    graphqlClient().mutate({
        mutation: updateMTOCartItemMutation,
        optimisticResponse: {
            __typename: 'Mutation',
            updateMadeToOrderCartItem: {
                __typename: 'madeToOrderCartItem',
                cartItemId,
                ...cartItemInput
            }
        },
        update: (cache, {data}) => updateMTOCartCacheAfterUpdate(cache, cartId, data),
        variables: {
            authType: TWO_LEGGED_AUTH_TYPE,
            updateMadeToOrderCartItemInput: {
                cartId,
                cartItemId: Number(cartItemId),
                ...cartItemInput
            }
        }
    });
