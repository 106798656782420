import queryString from 'query-string';

import {
    SET_ADDRESS_SUGGESTIONS_FAILURE,
    SET_ADDRESS_SUGGESTIONS_REQUEST,
    SET_ADDRESS_SUGGESTIONS_SUCCESS
} from '../../action-types';

import transformAddressValidationToAddressSuggestions from './util/transformAddressValidationToAddressSuggestions';

export const loadAddressSuggestionsForAddress = (payload, useGoogle = false) => {
    // Shared Fetch Methods
    const sharedFetchOptions = {
        onFailure: (dispatch, getState, error) => {
            error.response.json().then((response) => {
                dispatch({
                    saveAddressErrorStatus: response.statusCode,
                    type: SET_ADDRESS_SUGGESTIONS_FAILURE
                });
            });
        }
    };

    // GOOGLE ADDRESS VALIDATION
    const googleFetch = {
        ...sharedFetchOptions,
        onSuccess: (dispatch, getState, data) => {
            const transformedData = transformAddressValidationToAddressSuggestions(data);

            dispatch({
                addressSuggestions: transformedData,
                type: SET_ADDRESS_SUGGESTIONS_SUCCESS
            });
        },
        options: {
            body: JSON.stringify({
                address: {
                    addressLines: [payload?.addressOne, ...(payload?.addressTwo ? [payload.addressTwo] : [])],
                    city: payload?.city,
                    state: payload?.state,
                    zipcode: payload?.zip
                }
            }),
            method: 'POST'
        },
        url: '/aisles-online/api/address-validation'
    };

    // LEGACY EXPERIAN ADDRESS SUGGESTIONS
    const legacyFetch = {
        ...sharedFetchOptions,
        onSuccess: (dispatch, getState, data) => {
            dispatch({
                type: SET_ADDRESS_SUGGESTIONS_SUCCESS,
                ...data
            });
        },
        url: `/aisles-online/api/address-suggestions?${queryString.stringify(payload)}`
    };

    return {
        fetch: useGoogle ? googleFetch : legacyFetch,
        type: SET_ADDRESS_SUGGESTIONS_REQUEST
    };
};
