import {SET_PASSCODE, SET_PASSCODE_VALIDATION_ERROR_MESSAGE} from '../../action-types';

export const setPasscode = (passcode) => ({
    passcode,
    type: SET_PASSCODE
});

export const setPasscodeValidationErrorMessage = (validationErrorMessage) => ({
    type: SET_PASSCODE_VALIDATION_ERROR_MESSAGE,
    validationErrorMessage
});
