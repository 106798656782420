import {OrderStatus} from '@hy-vee/shared-utils-aisles-online';
import {calculateSubtotal} from '@hy-vee/shared-utils-pricing';
import {BigNumber} from 'bignumber.js';

import {ILocalItem} from 'client/views/components/order-history/add-order-items/local-cart-context';
import {IPromotion} from 'ts/types/order-item-types';
import {getActiveCart, getActiveCart_carts_cartItems_retailItem_item_WicItems} from 'autogen/getActiveCart';
import {GetWicCardDetails} from 'autogen/GetWicCardDetails';
import {GetWicCustomerBenefits} from 'autogen/GetWicCustomerBenefits';

import {POUND} from '../../../server/enums/aisles-online-api-quantity-type';
import {isBulk} from '../product-helpers';

const getMadeToOrderCartItemSubtotal = ({price, quantity}) => new BigNumber(price).times(quantity);

const getMadeToOrderCartItemEstimatedTax = ({price, quantity, taxExemptAmount}) => {
    const estimatedTax = Number(new BigNumber(price).minus(taxExemptAmount).times(quantity).times(0.07));

    return estimatedTax > 0 ? estimatedTax : 0;
};

const getMadeToOrderCartItemTax = ({tax, price, quantity, taxExemptAmount}) =>
    tax !== null
        ? tax
        : getMadeToOrderCartItemEstimatedTax({
              price,
              quantity,
              taxExemptAmount
          });

export const getCartItemQuantity = (cartItemQuantity, cartItemQuantityType, productAverageWeight) =>
    cartItemQuantityType === POUND && productAverageWeight === null ? cartItemQuantity * 4 : cartItemQuantity;

export const getPriceForQuantity = (cartItem, storeProduct) =>
    Number(new BigNumber(storeProduct.price).dividedBy(storeProduct.priceMultiple).times(cartItem.quantity).toFixed(2));

interface ICartItem {
    quantity: number;
    retailItem?: {
        item?: {
            unitAverageWeight: number | null;
        };
        soldByUnitOfMeasure: {
            code: string;
        } | null;
        tagPrice: number;
        tagPriceQuantity: number;
        ecommerceTagPrice: number | null;
        ecommerceTagPriceQuantity: number | null;
    } | null;
    storeProduct?: {
        storeProductId: string;
        isWeighted: boolean;
        price: number;
        priceMultiple: number;
    } | null;
}

export const getCartItemSubtotal = (cartItem: ICartItem, product, storeProduct, isItemApiEnabled?: boolean): number => {
    const {retailItem, quantity} = cartItem;

    if (isItemApiEnabled && retailItem && retailItem.item) {
        const isWeighted = retailItem.soldByUnitOfMeasure?.code !== 'CT' && Boolean(retailItem.item.unitAverageWeight);

        return calculateSubtotal(
            retailItem.ecommerceTagPrice ?? retailItem.tagPrice,
            retailItem.ecommerceTagPriceQuantity ?? retailItem.tagPriceQuantity,
            quantity * (isWeighted ? (retailItem.item.unitAverageWeight as number) : 1)
        );
    }

    return storeProduct.isWeighted && product.averageWeight
        ? Number(new BigNumber(storeProduct.price).times(quantity).times(product.averageWeight).toFixed(2))
        : getPriceForQuantity(cartItem, storeProduct);
};

export const getLocalCartItemSubtotal = (cartItem: ILocalItem): number => {
    const {quantity, product} = cartItem;
    const retailItem = product.item?.retailItems ? product.item?.retailItems[0] : null;

    if (!retailItem) {
        return 0;
    }

    const isWeighted = retailItem?.soldByUnitOfMeasure?.code !== 'CT' && Boolean(product.averageWeight);
    const averageWeight = isWeighted && product.averageWeight ? product.averageWeight : 1;

    return calculateSubtotal(
        retailItem.ecommerceTagPrice ?? retailItem.tagPrice,
        retailItem.ecommerceTagPriceQuantity ?? retailItem.tagPriceQuantity,
        quantity * averageWeight
    );
};

export const getRetailItemPriceForQuantity = (retailItem, quantity) =>
    Number(
        new BigNumber(retailItem.ecommerceTagPrice ?? retailItem.tagPrice)
            .dividedBy(retailItem.ecommerceTagPriceQuantity ?? retailItem.tagPriceQuantity)
            .times(quantity)
            .toFixed(2)
    );

export const getWicRetailItemTagPrice = (retailItem) => {
    let tagPrice = retailItem.ecommerceTagPrice ?? retailItem.tagPrice;

    if (retailItem?.wicPromotions?.perUnitRewardAmount) {
        // perUnitRewardAmount is an integer as pennies.
        tagPrice -= retailItem.wicPromotions.perUnitRewardAmount / 100;
    }

    return tagPrice;
};

export const getUnfixedRetailItemPriceForQuantity = (retailItem, quantity) => {
    const tagPrice = getWicRetailItemTagPrice(retailItem);

    return Number(
        new BigNumber(tagPrice)
            .dividedBy(retailItem.ecommerceTagPriceQuantity ?? retailItem.tagPriceQuantity)
            .times(quantity)
    );
};

export const getRetailItemSubtotal = (retailItem, quantity) => {
    const isWeighted = Boolean(retailItem.soldByUnitOfMeasure?.code !== 'CT');

    return isWeighted && retailItem.item.unitAverageWeight
        ? Number(
              new BigNumber(retailItem.ecommerceTagPrice ?? retailItem.tagPrice)
                  .times(quantity)
                  .times(retailItem.item.unitAverageWeight)
                  .toFixed(2)
          )
        : getRetailItemPriceForQuantity(retailItem, quantity);
};

export const getUnfixedRetailItemSubtotal = (retailItem, quantity) => {
    const isWeighted = Boolean(retailItem.soldByUnitOfMeasure?.code !== 'CT');

    const tagPrice = getWicRetailItemTagPrice(retailItem);

    return isWeighted && retailItem.item.unitAverageWeight
        ? Number(new BigNumber(tagPrice).times(quantity).times(retailItem.item.unitAverageWeight))
        : getUnfixedRetailItemPriceForQuantity(retailItem, quantity);
};

export const getGroceryCartItemCount = (cart) => {
    const quantity =
        cart &&
        Array.isArray(cart.cartItems) &&
        cart.cartItems.reduce(
            (accumulator, cartItem) =>
                cartItem.quantityType === POUND
                    ? accumulator + 1
                    : accumulator +
                      getCartItemQuantity(
                          cartItem.quantity,
                          cartItem.quantityType,
                          cartItem.storeProduct &&
                              cartItem.storeProduct.product &&
                              cartItem.storeProduct.product.averageWeight
                      ),
            0
        );

    return quantity || null;
};

export const getMadeToOrderCartItemCount = (madeToOrderCart) => {
    const quantity = madeToOrderCart.madeToOrderCartItems?.reduce((accumulator, madeToOrderCartItem) => {
        if (!madeToOrderCartItem?.parentCartItemId) {
            return accumulator + madeToOrderCartItem?.quantity!;
        }

        return accumulator;
    }, 0);

    return quantity || null;
};

export const getGraphQLCartTotal = (cartItems, isItemPricingEnabled) =>
    Number(
        cartItems
            .reduce(
                (accumulator, cartItem) =>
                    accumulator.plus(
                        getCartItemSubtotal(
                            cartItem,
                            cartItem.storeProduct.product,
                            cartItem.storeProduct,
                            isItemPricingEnabled
                        )
                    ),
                new BigNumber(0)
            )
            .toFixed(2)
    );

export const displayPrice = (arg?: number | null) => {
    if (arg === undefined || arg === null) {
        return '';
    }

    const price = `$${new BigNumber(Math.abs(arg)).toFixed(2)}`;

    return Math.sign(arg) === -1 ? `-${price}` : price;
};

export const getPerUnitOrMultiplePriceString = (product) => {
    if (product.priceMultiple > 1) {
        return `${product.priceMultiple} / ${displayPrice(product.price)}`;
    }

    if (isBulk(product)) {
        return `${displayPrice(product.price)}/lb`;
    }

    return product.isWeighted && product.averageWeight
        ? `$${new BigNumber(product.price).times(product.averageWeight).toFixed(2)}`
        : displayPrice(product.price);
};

export const displayCentsUnderADollar = (price) => {
    if (price < 1) {
        return `${new BigNumber(price).times(100).toFixed(0)}¢`;
    }

    return displayPrice(price);
};

export const getCartItemAccumulatedValues = (cartItems, products, storeProducts) =>
    cartItems.reduce(
        (accumulator, cartItem) => ({
            fuelSaver:
                accumulator.fuelSaver +
                new BigNumber(storeProducts[cartItem.storeProductId].fuelSaver)
                    .times(Math.floor(cartItem.quantity))
                    .toNumber(),
            quantity:
                accumulator.quantity +
                getCartItemQuantity(
                    cartItem.quantity,
                    cartItem.quantityType,
                    products[cartItem.productId].averageWeight
                ),
            subtotal:
                accumulator.subtotal +
                getCartItemSubtotal(cartItem, products[cartItem.productId], storeProducts[cartItem.storeProductId]),
            tax: accumulator.tax + cartItem.tax
        }),
        {
            fuelSaver: 0,
            quantity: 0,
            subtotal: 0,
            tax: 0
        }
    );

export const getPromoCodeTotal = (promotions, subtotal) => {
    const [percentagePromosTotal, nonPercentagePromosTotal] = promotions.reduce(
        ([percentagePromoAccumulator, nonPercentagePromoAccumulator], promotion) => {
            const promotionAmount = new BigNumber(promotion.amount);

            return promotion.isPercentage
                ? [promotionAmount.times(subtotal).plus(percentagePromoAccumulator), nonPercentagePromoAccumulator]
                : [percentagePromoAccumulator, promotionAmount.plus(nonPercentagePromoAccumulator)];
        },
        [0, 0]
    );

    return new BigNumber(nonPercentagePromosTotal).plus(percentagePromosTotal).toNumber();
};

export const getActualPromoCodeTotal = (orderPromotions) =>
    orderPromotions
        .reduce(
            (totalPromotionsAmount, {dollarDiscount}) => new BigNumber(totalPromotionsAmount).plus(dollarDiscount || 0),
            new BigNumber(0)
        )
        .toNumber();

export const getPromoCodeFuelSaverTotal = (orderPromotions) =>
    orderPromotions
        .reduce(
            (totalPromotionsAmount, {fuelSaverReward}) =>
                new BigNumber(totalPromotionsAmount).plus(fuelSaverReward || 0),
            new BigNumber(0)
        )
        .toNumber();

export const splitPromotionsByFuelSaver = (promotions) =>
    promotions.reduce(
        ([fuelSaverPromoAccumulator, nonFuelSaverPromoAccumulator], promotion) =>
            promotion.isFuelSaver
                ? [[...fuelSaverPromoAccumulator, promotion], nonFuelSaverPromoAccumulator]
                : [fuelSaverPromoAccumulator, [...nonFuelSaverPromoAccumulator, promotion]],
        [[], []]
    );

export const orderHasBeenTenderedByStatusId = (orderStatusId) =>
    OrderStatus.ORDER_TENDERED <= orderStatusId && orderStatusId <= OrderStatus.ORDER_COMPLETE;

export const getGraphQLCartItemAccumulatedValues = (
    cartItems: (ICartItem & {
        quantity: number;
        quantityType: string;
        tax: number;
        retailItem: {};
        storeProduct: {fuelSaver: number; product: {averageWeight: number}};
    })[],
    isItemPricingEnabled?: boolean
) =>
    cartItems.reduce(
        (accumulator, cartItem) => ({
            fuelSaver:
                accumulator.fuelSaver +
                new BigNumber(cartItem.storeProduct.fuelSaver).times(Math.floor(cartItem.quantity)).toNumber(),
            quantity:
                accumulator.quantity +
                getCartItemQuantity(
                    cartItem.quantity,
                    cartItem.quantityType,
                    cartItem.storeProduct.product.averageWeight
                ),
            retailItemTotal:
                accumulator.retailItemTotal +
                (cartItem.retailItem ? getRetailItemSubtotal(cartItem.retailItem, cartItem.quantity) : 0),
            subtotal: new BigNumber(accumulator.subtotal)
                .plus(
                    getCartItemSubtotal(
                        cartItem,
                        cartItem.storeProduct.product,
                        cartItem.storeProduct,
                        isItemPricingEnabled
                    )
                )
                .toNumber(),
            tax: accumulator.tax + cartItem.tax
        }),
        {
            fuelSaver: 0,
            quantity: 0,
            retailItemTotal: 0,
            subtotal: 0,
            tax: 0
        }
    );

export const getGraphQLMadeToOrderCartItemAccumulatedValues = (cartItems) =>
    cartItems.reduce(
        (accumulator, cartItem) => ({
            serviceFee: Number(new BigNumber(accumulator.serviceFee).plus(Number(cartItem.fulfillmentFee))),
            subtotal: Number(new BigNumber(accumulator.subtotal).plus(getMadeToOrderCartItemSubtotal(cartItem))),
            tax: Number(new BigNumber(accumulator.tax).plus(getMadeToOrderCartItemTax(cartItem)))
        }),
        {
            serviceFee: 0,
            subtotal: 0,
            tax: 0
        }
    );

export const calculateGiftCardsWithRemainingBalances = (giftCards, giftCardTotal) => {
    let totalAppliedGiftCardAmount =
        giftCardTotal.groceryGiftCardAppliedAmount + giftCardTotal.mtoGiftCardAppliedAmount;

    return giftCards.map((giftCard) => {
        const remainingBalance = giftCard.balance - totalAppliedGiftCardAmount;

        totalAppliedGiftCardAmount = Math.max(totalAppliedGiftCardAmount - giftCard.balance, 0);

        return {
            ...giftCard,
            remainingBalance: Math.max(remainingBalance, 0)
        };
    });
};

export const getTotalClpePromotionRewardValue = (clpePromotions) =>
    clpePromotions.reduce((prev, next) => prev + next.rewardAmount, 0) / 100;

const basketLevelReducerCheck = (promotion: IPromotion) => {
    return promotion.id === null ? promotion.rewardAmount : 0;
};

export const getBasketLevelClpePromotionRewardValue = (clpePromotions: IPromotion[]) =>
    clpePromotions.reduce((sum, promotion) => sum + basketLevelReducerCheck(promotion), 0) / 100;

export const getWicDiscountAmount = (
    grocery: getActiveCart,
    wicCardDetails: GetWicCardDetails | undefined,
    isWicApplied: boolean,
    isSnapEBT: boolean
): number => {
    const discountAmount: number =
        typeof grocery !== 'undefined' &&
        grocery.carts &&
        Array.isArray(grocery.carts) &&
        grocery.carts.length &&
        grocery.carts[0].cartItems
            ? Number(
                  grocery.carts[0].cartItems.reduce((discountTotal, cartItem) => {
                      if (
                          isSnapEBT ||
                          typeof wicCardDetails === 'undefined' ||
                          !wicCardDetails.getWicCardDetails ||
                          !cartItem.wicAppliedQty ||
                          !cartItem?.retailItem?.item?.WicItems?.length ||
                          !isWicApplied
                      ) {
                          return discountTotal;
                      }

                      return (
                          discountTotal +
                          Number(new BigNumber(cartItem.wicCartItem?.wicLineSubtotalWithPromotions ?? 0).toFixed(2))
                      );
                  }, 0)
              )
            : 0;

    return discountAmount;
};

export const getShouldShowWicOptionsPage = (
    grocery: getActiveCart,
    wicCustomerBenefits: GetWicCustomerBenefits | undefined
): boolean => {
    let shouldShowWicOptionsPage = false;

    const benefitSubcategories =
        wicCustomerBenefits?.getWicCustomerBenefits?.wicItemsBenefits?.map(
            (benefit) => `${benefit.subcategory.categoryCode}.${benefit.subcategory.subcategoryCode}`
        ) || [];

    if (
        typeof grocery !== 'undefined' &&
        grocery.carts &&
        Array.isArray(grocery.carts) &&
        grocery.carts.length &&
        grocery.carts[0].cartItems
    ) {
        for (const cartItem of grocery.carts[0].cartItems) {
            if (cartItem?.retailItem?.item?.WicItems?.length) {
                const wicItem: getActiveCart_carts_cartItems_retailItem_item_WicItems =
                    cartItem?.retailItem?.item?.WicItems[0];
                const categoryCode = wicItem.wicSubcategory.categoryCode ?? -1;
                const subCategoryCode = wicItem.wicSubcategory.subcategoryCode ?? -1;
                const userHasBroadbandBenefits =
                    wicItem.isBroadbandAllowed && benefitSubcategories?.includes(`${categoryCode}.000`);

                const groupCode = userHasBroadbandBenefits
                    ? `${categoryCode}.000`
                    : `${categoryCode}.${subCategoryCode}`;

                if (
                    benefitSubcategories?.includes(groupCode) ||
                    benefitSubcategories?.includes(`${categoryCode}.${subCategoryCode}`)
                ) {
                    shouldShowWicOptionsPage = true;
                }
            }

            if (shouldShowWicOptionsPage) break;
        }
    }

    return shouldShowWicOptionsPage;
};
