import {useCallback, useMemo, FC, PropsWithChildren} from 'react';
import getConfig from 'next/config';
import {useQuery} from '@apollo/client';

import * as featuresToggleNames from 'client/enums/feature-toggle-names';

import {useUserDetails} from '../user-details';
import {GetUnleashFeatureToggles} from '../../../autogen/GetUnleashFeatureToggles';
import {getUnleashFeatures} from '../../graphql/queries/customer-and-feature-queries';
import {THREE_LEGGED_AUTH_TYPE, TWO_LEGGED_AUTH_TYPE} from '../../enums/auth-types';

import FeatureToggleContext from './context';

export const allFeatures = Object.values(featuresToggleNames);

/**
 * Feature names from [feature toggles file]{@link featuresToggleNames}
 */
export type FeatureNameType = (typeof allFeatures)[number];

const buildDefaultMap = (): Map<FeatureNameType, boolean> => {
    return new Map(allFeatures.map((feature) => [feature, false]));
};

const FeatureToggleProvider: FC<PropsWithChildren<PropsWithChildren>> = ({children}) => {
    const {isAuthenticated, storeId, error: userDetailsError, loading: userDetailsLoading} = useUserDetails();

    const store = useMemo(() => storeId ?? getConfig().publicRuntimeConfig.defaultStoreId, [storeId]);

    const {
        data,
        loading: queryLoading,
        error: queryError
    } = useQuery<GetUnleashFeatureToggles>(getUnleashFeatures, {
        skip: userDetailsLoading,
        variables: {
            authType: isAuthenticated ? THREE_LEGGED_AUTH_TYPE : TWO_LEGGED_AUTH_TYPE,
            featureNames: allFeatures,
            properties: {
                storeCode: store.toString()
            }
        }
    });

    const loading = useMemo(
        () => Boolean((userDetailsLoading || queryLoading) && !data && !queryError),
        [queryLoading, userDetailsLoading, data, queryError]
    );

    const error = useMemo(() => {
        if (userDetailsError) {
            return userDetailsError;
        }

        if (!queryLoading && queryError) {
            return queryError;
        }

        return undefined;
    }, [userDetailsError, queryLoading, queryError]);

    const featureMap = useMemo(() => {
        if (!queryLoading && data && data.unleashFeatureToggles) {
            const featureToggles = data.unleashFeatureToggles;

            return new Map<FeatureNameType, boolean>(
                featureToggles.map(({active, featureName}) => [featureName as FeatureNameType, active])
            );
        }

        return buildDefaultMap();
    }, [data, queryLoading]);

    const featureEnabled = useCallback((feature: FeatureNameType) => !!featureMap.get(feature), [featureMap]);

    const featuresEnabled = useCallback(
        (features: FeatureNameType[]) => features.every(featureEnabled),
        [featureEnabled]
    );

    return (
        <FeatureToggleContext.Provider
            value={{
                error,
                featureEnabled,
                featureMap,
                featuresEnabled,
                loading
            }}
        >
            {children}
        </FeatureToggleContext.Provider>
    );
};

export default FeatureToggleProvider;
