import {
    SET_FULFILLMENT_CHANGE_PAGE_DATA_REQUEST,
    SET_FULFILLMENT_CHANGE_PAGE_DATA_FAILURE,
    SET_FULFILLMENT_CHANGE_PAGE_DATA_SUCCESS
} from '../../action-types';

export const loadFulfillmentChangePageData = (payload) => ({
    fetch: {
        onFailure: (dispatch, getState, error) => {
            dispatch({
                response: error.response,
                type: SET_FULFILLMENT_CHANGE_PAGE_DATA_FAILURE
            });
        },
        onSuccess: (dispatch, getState, data) => {
            if (data._redirectLocation) {
                window.location.replace(data._redirectLocation);
            } else {
                dispatch({
                    type: SET_FULFILLMENT_CHANGE_PAGE_DATA_SUCCESS,
                    ...data
                });
            }
        },
        url: payload
            ? `/aisles-online/api/fulfillment-change${payload.queryParams}`
            : `/aisles-online/api/fulfillment-change${window.location.search}`
    },
    type: SET_FULFILLMENT_CHANGE_PAGE_DATA_REQUEST
});
