import BigNumber from 'bignumber.js';
import {getCartItemQuantity} from '@hy-vee/shared-utils-aisles-online';

import {ICartItem} from 'autogen/ICartItem';
import {LocationEcommerceStatus} from 'autogen/globalTypes';
import {GetMadeToOrderCart_madeToOrderCart_madeToOrderCartItems} from 'autogen/GetMadeToOrderCart';
import {getActiveCartForGlobalNavigation_carts} from 'autogen/getActiveCartForGlobalNavigation';

import {ICart} from '../types/ICart';

export const getNumberOfAoItems = (cartItems: ICartItem[]): number =>
    cartItems.reduce(
        (accumulator, cartItem) =>
            accumulator +
            getCartItemQuantity(
                cartItem.quantity,
                cartItem.quantityType,
                cartItem.storeProduct?.product?.averageWeight || null
            ),
        0
    );

export const getNumberOfMtoItems = (
    mtoCartItems: (GetMadeToOrderCart_madeToOrderCart_madeToOrderCartItems | null)[]
): number => {
    const cartItems = mtoCartItems[0] ? mtoCartItems : [];

    return cartItems.reduce((accum: number, cartItem: GetMadeToOrderCart_madeToOrderCart_madeToOrderCartItems) => {
        return accum + Number(cartItem.quantity);
    }, 0);
};

export const getMtoCartTotal = (mtoCartItems: ({price: number | null; quantity: number} | null)[]): number =>
    Number(
        mtoCartItems
            .reduce((total, product) => {
                if (product && product.price) {
                    const productPrice = new BigNumber(product.price);

                    return new BigNumber(total.plus(productPrice.times(product.quantity)));
                }

                return total;
            }, new BigNumber(0))
            .toFixed(2)
    );

export const getEcommereceStatus = (
    cart: getActiveCartForGlobalNavigation_carts | ICart | undefined
): LocationEcommerceStatus =>
    cart?.fulfillmentLocation?.fulfillmentStoreLocation?.ecommerceStatus || LocationEcommerceStatus.ACTIVE;
