export const ACKNOWLEDGE_API_CALL_FAILURE = 'ACKNOWLEDGE_API_CALL_FAILURE';
export const SET_ADDRESS_SUGGESTIONS_FAILURE = 'SET_ADDRESS_SUGGESTIONS_FAILURE';
export const SET_ADDRESS_SUGGESTIONS_REQUEST = 'SET_ADDRESS_SUGGESTIONS_REQUEST';
export const SET_ADDRESS_SUGGESTIONS_SUCCESS = 'SET_ADDRESS_SUGGESTIONS_SUCCESS';
export const CLEAR_ADDRESS_SUGGESTIONS = 'CLEAR_ADDRESS_SUGGESTIONS';
export const SET_ENTERED_DELIVERY_ADDRESS = 'SET_ENTERED_DELIVERY_ADDRESS';
export const SET_FULFILLMENT_DATA_SUCCESS = 'SET_FULFILLMENT_DATA_SUCCESS';
export const SET_SHOULD_DISPLAY_FULFILLMENT_INFO = 'SET_SHOULD_DISPLAY_FULFILLMENT_INFO';
export const SET_FULFILLMENT_CHANGE_PAGE_DATA_REQUEST = 'SET_FULFILLMENT_CHANGE_PAGE_DATA_REQUEST';
export const SET_FULFILLMENT_CHANGE_PAGE_DATA_SUCCESS = 'SET_FULFILLMENT_CHANGE_PAGE_DATA_SUCCESS';
export const SET_FULFILLMENT_CHANGE_PAGE_DATA_FAILURE = 'SET_FULFILLMENT_CHANGE_PAGE_DATA_FAILURE';
export const RESET_FULFILLMENT_CHANGE_REMOVED_CART_ITEMS = 'RESET_FULFILLMENT_CHANGE_REMOVED_CART_ITEMS';
export const SET_TIME_RESERVATION_PAGE_DATA_REQUEST = 'SET_TIME_RESERVATION_PAGE_DATA_REQUEST';
export const SET_TIME_RESERVATION_PAGE_DATA_SUCCESS = 'SET_TIME_RESERVATION_PAGE_DATA_SUCCESS';
export const SET_TIME_RESERVATION_PAGE_DATA_FAILURE = 'SET_TIME_RESERVATION_PAGE_DATA_FAILURE';
export const SET_PASSCODE = 'SET_PASSCODE';
export const SET_PASSCODE_FORM_SUBMITTED = 'SET_PASSCODE_FORM_SUBMITTED';
export const SET_PASSCODE_VALIDATION_ERROR_MESSAGE = 'SET_PASSCODE_VALIDATION_ERROR_MESSAGE';
export const SET_NO_SUBS_AVAILABLE = 'SET_NO_SUBS_AVAILABLE';
export const SET_SUBSTITUTIONS_ERROR = 'SET_SUBSTITUTIONS_ERROR';
export const UPDATE_CART_LOCATION_FAILURE = 'UPDATE_CART_LOCATION_FAILURE';
export const UPDATE_CART_LOCATION_REQUEST = 'UPDATE_CART_LOCATION_REQUEST';
export const UPDATE_CART_LOCATION_SUCCESS = 'UPDATE_CART_LOCATION_SUCCESS';
export const SET_CUSTOMER_CARDS_FAILURE = 'SET_CUSTOMER_CARDS_FAILURE';
export const SET_CUSTOMER_CARDS_REQUEST = 'SET_CUSTOMER_CARDS_REQUEST';
export const SET_CUSTOMER_CARDS_SUCCESS = 'SET_CUSTOMER_CARDS_SUCCESS';
export const UPDATE_CUSTOMER_CARD_SUCCESS = 'UPDATE_CUSTOMER_CARD_SUCCESS';
export const UPDATE_CUSTOMER_CARD_FAILURE = 'UPDATE_CUSTOMER_CARD_FAILURE';
export const UPDATE_CUSTOMER_CARD_REQUEST = 'UPDATE_CUSTOMER_CARD_REQUEST';
export const SET_GIFT_CARD_TOTAL = 'SET_GIFT_CARD_TOTAL';
export const SET_SHOULD_APPLY_GIFT_CARDS = 'SET_SHOULD_APPLY_GIFT_CARDS';
export const SET_GIFT_CARDS_FAILURE = 'SET_GIFT_CARDS_FAILURE';
export const SET_GIFT_CARDS_REQUEST = 'SET_GIFT_CARDS_REQUEST';
export const SET_GIFT_CARDS_SUCCESS = 'SET_GIFT_CARDS_SUCCESS';
export const SET_GIFT_CARD_ERROR = 'SET_GIFT_CARD_ERROR';
export const ADD_GIFT_CARD_SUCCESS = 'ADD_GIFT_CARD_SUCCESS';
export const ADD_GIFT_CARD_FAILURE = 'ADD_GIFT_CARD_FAILURE';
export const ADD_GIFT_CARD_REQUEST = 'ADD_GIFT_CARD_REQUEST';
export const EDIT_GIFT_CARD_NICKNAME_SUCCESS = 'EDIT_GIFT_CARD_NICKNAME_SUCCESS';
export const EDIT_GIFT_CARD_NICKNAME_FAILURE = 'EDIT_GIFT_CARD_NICKNAME_FAILURE';
export const EDIT_GIFT_CARD_NICKNAME_REQUEST = 'EDIT_GIFT_CARD_NICKNAME_REQUEST';
export const DELETE_GIFT_CARD_REQUEST = 'DELETE_GIFT_CARD_REQUEST';
export const DELETE_GIFT_CARD_SUCCESS = 'DELETE_GIFT_CARD_SUCCESS';
export const DELETE_GIFT_CARD_FAILURE = 'DELETE_GIFT_CARD_FAILURE';
export const SET_SELECTED_CUSTOMER_CARD = 'SET_SELECTED_CUSTOMER_CARD';
export const SET_NEW_CUSTOMER_CARD = 'SET_NEW_CUSTOMER_CARD';
export const SET_PAYMENT_CHECKOUT_VIEW = 'SET_PAYMENT_CHECKOUT_VIEW';
export const SET_SELECTED_TIME_RESERVATION_TAB = 'SET_SELECTED_TIME_RESERVATION_TAB';
export const SET_SELECTED_TIME_DURATION_TAB = 'SET_SELECTED_TIME_DURATION_TAB';
export const SET_FULFILLMENT_TIME_ERROR = 'SET_FULFILLMENT_TIME_ERROR';
export const SET_IS_MTO = 'SET_IS_MTO';
export const SET_ADDRESS_ERROR_STATUS = 'SET_ADDRESS_ERROR_STATUS';
export const SET_STORE_SELECTION_TYPE_TAB = 'SET_STORE_SELECTION_TYPE_TAB';
export const SET_STORE_SELECTION_PICKUP_PAGE_DATA_FAILURE = 'SET_STORE_SELECTION_PICKUP_PAGE_DATA_FAILURE';
export const SET_STORE_SELECTION_PICKUP_PAGE_DATA_REQUEST = 'SET_STORE_SELECTION_PICKUP_PAGE_DATA_REQUEST';
export const SET_STORE_SELECTION_PICKUP_PAGE_DATA_SUCCESS = 'SET_STORE_SELECTION_PICKUP_PAGE_DATA_SUCCESS';
export const SET_STORE_SELECTION_DELIVERY_PAGE_DATA_FAILURE = 'SET_STORE_SELECTION_DELIVERY_PAGE_DATA_FAILURE';
export const SET_STORE_SELECTION_DELIVERY_PAGE_DATA_REQUEST = 'SET_STORE_SELECTION_DELIVERY_PAGE_DATA_REQUEST';
export const SET_STORE_SELECTION_DELIVERY_PAGE_DATA_SUCCESS = 'SET_STORE_SELECTION_DELIVERY_PAGE_DATA_SUCCESS';
export const SET_PICKUP_LOCATIONS_DISTANCE = 'SET_PICKUP_LOCATIONS_DISTANCE';
export const SET_SHOULD_DISPLAY_NEW_ADDRESS_FORM = 'SET_SHOULD_DISPLAY_NEW_ADDRESS_FORM';
export const SET_SHOULD_SHOW_ALL_MY_LOCATIONS = 'SET_SHOULD_SHOW_ALL_MY_LOCATIONS';
export const SET_SHOULD_SHOW_ALL_MY_ADDRESSES = 'SET_SHOULD_SHOW_ALL_MY_ADDRESSES';
export const SET_SELECTED_DELIVERY_ADDRESS = 'SET_SELECTED_DELIVERY_ADDRESS';
export const SET_ORDER_SUMMARY_AFTER_PURCHASE_PAGE_DATA_FAILURE = 'SET_ORDER_SUMMARY_AFTER_PURCHASE_PAGE_DATA_FAILURE';
export const SET_ORDER_SUMMARY_AFTER_PURCHASE_PAGE_DATA_SUCCESS = 'SET_ORDER_SUMMARY_AFTER_PURCHASE_PAGE_DATA_SUCCESS';
export const SET_ORDER_SUMMARY_AFTER_PURCHASE_PAGE_DATA_REQUEST = 'SET_ORDER_SUMMARY_AFTER_PURCHASE_PAGE_DATA_REQUEST';
export const SET_MTO_ORDER_VALIDATED = 'SET_MTO_ORDER_VALIDATED';
export const SET_SAVE_NEW_DELIVERY_ADDRESS_REQUEST = 'SET_SAVE_NEW_DELIVERY_ADDRESS_REQUEST';
export const SET_SAVE_NEW_DELIVERY_ADDRESS_SUCCESS = 'SET_SAVE_NEW_DELIVERY_ADDRESS_SUCCESS';
export const SET_SAVE_NEW_DELIVERY_ADDRESS_FAILURE = 'SET_SAVE_NEW_DELIVERY_ADDRESS_FAILURE';
export const CLEAR_SAVE_ADDRESS_ERROR_STATUS = 'CLEAR_SAVE_ADDRESS_ERROR_STATUS';
export const SET_AUTHENTICATION_SUCCESS = 'SET_AUTHENTICATION_SUCCESS';
export const SELECT_MTO_DELIVERY_ADDRESS_REQUEST = 'SELECT_MTO_DELIVERY_ADDRESS_REQUEST';
export const SELECT_MTO_DELIVERY_ADDRESS_SUCCESS = 'SELECT_MTO_DELIVERY_ADDRESS_SUCCESS';
export const SELECT_MTO_DELIVERY_ADDRESS_FAILURE = 'SELECT_MTO_DELIVERY_ADDRESS_FAILURE';
export const CLEAR_FILTERED_PICKUP_STORES = 'CLEAR_FILTERED_PICKUP_STORES';
export const SET_CART_PROMOTION_FORM_ERROR = 'SET_CART_PROMOTION_FORM_ERROR';
export const TOGGLE_SELECT_ALL_CART_ITEMS = 'TOGGLE_SELECT_ALL_CART_ITEMS';
export const TOGGLE_SELECT_ONE_GROCERY_CART_ITEM = 'TOGGLE_SELECT_ONE_GROCERY_CART_ITEM';
export const TOGGLE_SELECT_ONE_MTO_CART_ITEM = 'TOGGLE_SELECT_ONE_MTO_CART_ITEM';
export const REMOVE_CART_ITEMS = 'REMOVE_CART_ITEMS';
export const TOGGLE_ACTIVE_MODAL = 'TOGGLE_ACTIVE_MODAL';
export const REMOVE_CART_ITEM_SUCCESS = 'REMOVE_CART_ITEM_SUCCESS';
export const SET_SELECTED_SPD_OPTIONS = 'SET_SELECTED_SPD_OPTIONS';
export const SET_SELECTED_HIERARCHY = 'SET_SELECTED_HIERARCHY';
export const CLEAR_DELETE_CARD_ERROR = 'CLEAR_DELETE_CARD_ERROR';
export const DELETE_CARD_SUCCESS = 'DELETE_CARD_SUCCESS';
export const DELETE_CARD_REQUEST = 'DELETE_CARD_REQUEST';
export const DELETE_CARD_FAILURE = 'DELETE_CARD_FAILURE';
export const SET_DELETE_CARD_ERROR = 'SET_DELETE_CARD_ERROR';
export const SET_ADD_CARD_ERROR = 'SET_ADD_CARD_ERROR';
export const SET_CUSTOMER_CARDS = 'SET_CUSTOMER_CARDS';
export const SET_ADD_TO_LIST_MODAL_ERROR = 'SET_ADD_TO_LIST_MODAL_ERROR';
export const SET_WELCOME_BACK_MODAL_ERROR = 'SET_WELCOME_BACK_MODAL_ERROR';
export const SET_CUSTOMER = 'SET_CUSTOMER';
export const SET_STORE = 'SET_STORE';
export const SET_ORDER_IN_PROGRESS = 'SET_ORDER_IN_PROGRESS';
export const PLACE_ORDER_REQUEST = 'PLACE_ORDER_REQUEST';
export const PLACE_ORDER_SUCCESS = 'PLACE_ORDER_SUCCESS';
export const PLACE_ORDER_FAILURE = 'PLACE_ORDER_FAILURE';
export const CLEAR_PLACE_ORDER_ERROR_STATUS = 'CLEAR_PLACE_ORDER_ERROR_STATUS';
export const SET_FULFILLMENT_CHANGE_SUCCESSFULLY_SUBMITTED = 'SET_FULFILLMENT_CHANGE_SUCCESSFULLY_SUBMITTED';
export const SET_FULFILLMENT_TIME_WARNING = 'SET_FULFILLMENT_TIME_WARNING';
export const REMOVE_FULFILLMENT_TIME_WARNING = 'REMOVE_FULFILLMENT_TIME_WARNING';
export const SET_BILLING_PHONE = 'SET_BILLING_PHONE';
export const SET_TIME_SLOT_SNATCHED_ERROR = 'SET_TIME_SLOT_SNATCHED_ERROR';
export const SET_GROCERY_TIP = 'SET_GROCERY_TIP';
export const SET_GROCERY_DELIVERY_TIP_STAGE = 'SET_GROCERY_DELIVERY_TIP_STAGE';
export const SET_CITRUS_BANNER_URL_REQUEST = 'SET_CITRUS_BANNER_URL_REQUEST';
export const SET_CITRUS_BANNER_URL_SUCCESS = 'SET_CITRUS_BANNER_URL_SUCCESS';
export const SET_CITRUS_BANNER_URL_FAILURE = 'SET_CITRUS_BANNER_URL_FAILURE';
export const SET_RESERVATION_STEPPER_INDEX = 'SET_RESERVATION_STEPPER_INDEX';
export const SET_TAX_EXEMPTION = 'SET_TAX_EXEMPTION';
export const SET_LOGGING_IN_TO_WIC_VENDOR = 'SET_LOGGING_IN_TO_WIC_VENDOR';
export const PAYPAL_CREATE_CHECKOUT_ID_REQUEST = 'PAYPAL_CREATE_CHECKOUT_ID_REQUEST';
export const PAYPAL_SET_CHECKOUT_ID = 'PAYPAL_SET_CHECKOUT_ID';
export const PAYPAL_CREATE_ORDER_REQUEST = 'PAYPAL_CREATE_ORDER_REQUEST';
export const PAYPAL_SET_ORDER = 'PAYPAL_SET_ORDER';
export const SELECTED_CARD_ID = 'SELECTED_CARD_ID';
export const PAYPAL_SET_ERROR = 'PAYPAL_SET_ERROR';
