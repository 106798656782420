import {
    SET_TIME_RESERVATION_PAGE_DATA_SUCCESS,
    SET_TIME_RESERVATION_PAGE_DATA_REQUEST,
    SET_TIME_RESERVATION_PAGE_DATA_FAILURE
} from '../../action-types';

export const loadTimeReservationPageData = (fulfillmentLocationId, ignoreCartTimeSlot) => ({
    fetch: {
        onFailure: (dispatch, getState, error) => {
            dispatch({
                response: error.response,
                type: SET_TIME_RESERVATION_PAGE_DATA_FAILURE
            });
        },
        onSuccess: (dispatch, getState, data) => {
            dispatch({
                ignoreCartTimeSlot,
                type: SET_TIME_RESERVATION_PAGE_DATA_SUCCESS,
                ...data
            });
        },
        url: `/aisles-online/api/time-reservation-page-data${
            fulfillmentLocationId ? `?fulfillmentLocationId=${fulfillmentLocationId}` : ''
        }`
    },
    type: SET_TIME_RESERVATION_PAGE_DATA_REQUEST
});
