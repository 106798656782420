import {
    SET_STORE_SELECTION_DELIVERY_PAGE_DATA_SUCCESS,
    SET_STORE_SELECTION_DELIVERY_PAGE_DATA_REQUEST,
    SET_STORE_SELECTION_DELIVERY_PAGE_DATA_FAILURE
} from '../../action-types';

export const loadStoreSelectionDeliveryPageData = () => (dispatch, getState) => {
    const storeSelectionState = getState().storeSelection;

    const query = storeSelectionState.isMto ? 'isMto=true' : `getCarts=${!storeSelectionState.carts.length}`;

    return dispatch({
        fetch: {
            onFailure: (dispatchFromMiddleWare, getStateFromMiddleware, error) => {
                dispatchFromMiddleWare({
                    response: error.response,
                    type: SET_STORE_SELECTION_DELIVERY_PAGE_DATA_FAILURE
                });
            },
            onSuccess: (dispatchFromMiddleWare, getStateFromMiddleware, data) => {
                dispatchFromMiddleWare({
                    type: SET_STORE_SELECTION_DELIVERY_PAGE_DATA_SUCCESS,
                    ...data
                });
            },
            url: `/aisles-online/api/store-selection/delivery?${query}`
        },
        type: SET_STORE_SELECTION_DELIVERY_PAGE_DATA_REQUEST
    });
};
