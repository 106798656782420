export const cartForCacheIdentify = (cartId: number | string) => ({
    __typename: 'cart',
    cartId: `${cartId}`
});

export const cartItemForCacheIdentify = (cartItemId: string) => ({
    __typename: 'cartItem',
    cartItemId
});

export const listForCacheIdentify = (listId: string) => ({
    __typename: 'list',
    listId
});

export const listItemForCacheIdentify = (listItemId: string) => ({
    __typename: 'listItem',
    listItemId
});
