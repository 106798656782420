import {graphqlClient} from '../../graphql-client';
import {cartItemQuantityMutateFragment, cartItemUserSubstitutionMutateFragment} from '../../queries/cart-item-queries';

import {cartItemForCacheIdentify} from './cache-identify-helpers';

export const updateCartItemAfterUserSelectedSubstitution = (cartItem, selectedSubstitution) => {
    const client = graphqlClient();

    client.writeFragment({
        data: {
            ...cartItemForCacheIdentify(cartItem.cartItemId),
            userSelectedSubstitution: selectedSubstitution,
            userSelectedSubstitutionId: selectedSubstitution?.userSelectedSubstitutionId
        },
        fragment: cartItemUserSubstitutionMutateFragment
    });
};

export const updateCartItemsAfterUserSelectedSubstitutionDelete = (cartItem) => {
    const client = graphqlClient();

    client.writeFragment({
        data: {
            ...cartItemForCacheIdentify(cartItem.cartItemId),
            userSelectedSubstitution: null,
            userSelectedSubstitutionId: null
        },
        fragment: cartItemUserSubstitutionMutateFragment
    });
};

export const updateCartItemCacheAfterQuantityChanged = (cartItemId, quantity) => {
    const client = graphqlClient();

    client.writeFragment({
        data: {
            ...cartItemForCacheIdentify(cartItemId),
            quantity
        },
        fragment: cartItemQuantityMutateFragment
    });
};
