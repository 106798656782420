import {gql} from '@apollo/client';

export const productImageFragment = gql`
    fragment ProductImageFragment on productImage {
        productId
        uri
        size
        type
        isPrimary
    }
`;
