class GraphqlFetchError extends Error {
    public readonly response: Response;

    public constructor(message: string, response: Response) {
        super(message);

        this.name = 'GraphqlFetchError';
        this.response = response;
    }
}

const graphqlFetch = async (url: RequestInfo, options?: RequestInit) =>
    fetch(url, {
        ...options,
        headers: {
            'content-type': 'application/json'
        }
    }).then((response) => {
        if (!response.ok) {
            const error = new GraphqlFetchError(`Error fetching ${url}`, response);

            throw error;
        }

        return response;
    });

export default graphqlFetch;
