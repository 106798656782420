/**
 * Transforms address validation data into a structured format suitable for address suggestions.
 *
 * This function takes in the result of an address validation service and returns a response
 * containing a list of addresses and a verification level. The function processes the data to
 * determine if the address is validated and constructs an address suggestion based on the validation result.
 *
 * @param {Awaited<ReturnType<typeof addressValidator>>} data - The result data from the address validator service.
 *
 * @returns {AddressValidationResponse} An object containing the list of address suggestions and the verification level.
 *
 * The returned object includes:
 * - `addresses`: An array of address objects, each containing:
 *   - `addressOne` (string): The primary address line.
 *   - `addressTwo` (string, optional): The secondary address line.
 *   - `city` (string): The city of the address.
 *   - `state` (string): The state of the address.
 *   - `zip` (string): The ZIP code of the address.
 * - `verifyLevel` (LEGACY_VERIFY_LEVELS): The verification level of the address, either 'NONE' or 'VERIFIED'.
 *
 * Example usage:
 * ```
 * const validationResult = await addressValidator(someAddressData);
 * const addressSuggestions = transformAddressValidationToAddressSuggestions(validationResult.result);
 * ```
 */

enum LEGACY_VERIFY_LEVELS {
    NONE = 'NONE',
    VERIFIED = 'VERIFIED'
}

interface AddressValidationResponse {
    addresses: {
        addressOne: string;
        addressTwo?: string;
        city: string;
        state: string;
        zip: string;
    }[];
    verifyLevel: LEGACY_VERIFY_LEVELS;
}

const transformAddressValidationToAddressSuggestions = (data?: Awaited<ReturnType<any>>): AddressValidationResponse => {
    // Build Address
    let addresses: AddressValidationResponse['addresses'] = [];
    let verifyLevel: AddressValidationResponse['verifyLevel'] = LEGACY_VERIFY_LEVELS.NONE;

    const postalAddress = data?.rawResponse?.result?.address?.postalAddress || {};

    if (
        data &&
        data.verdict &&
        postalAddress &&
        postalAddress.addressLines &&
        postalAddress.addressLines[0] &&
        postalAddress.locality &&
        postalAddress.administrativeArea &&
        postalAddress.postalCode
    ) {
        const {verdict} = data;

        // If User Input Is Valid
        if (verdict.userInputValidationStatus) {
            verifyLevel = LEGACY_VERIFY_LEVELS.VERIFIED;
        }

        // Build Suggested Addresses
        if (verdict.returnedAddressValidationStatus) {
            addresses = [
                {
                    addressOne: postalAddress.addressLines[0],
                    addressTwo: postalAddress.addressLines?.[1],
                    city: postalAddress.locality,
                    state: postalAddress.administrativeArea,
                    zip: postalAddress.postalCode
                }
            ];
        }
    }

    // Found Verified Address Suggestion
    return {
        addresses,
        verifyLevel
    };
};

export default transformAddressValidationToAddressSuggestions;
