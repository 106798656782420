import {getMadeToOrderCart} from '../../queries/mto-queries';

export const updateMTOCartCacheAfterDelete = (client, cartId, deletedCartItemId) => {
    const clientData = client.readQuery({
        query: getMadeToOrderCart,
        variables: {cartId: cartId.toLowerCase()}
    });

    const madeToOrderCart = clientData.madeToOrderCart;

    const updatedClientData = {
        ...clientData,
        madeToOrderCart: {
            ...madeToOrderCart,
            madeToOrderCartItems: madeToOrderCart.madeToOrderCartItems.filter(
                (cartItem) =>
                    Number(cartItem.cartItemId) !== deletedCartItemId && cartItem.parentCartItemId !== deletedCartItemId
            )
        }
    };

    client.writeQuery({
        data: updatedClientData,
        query: getMadeToOrderCart
    });
};

export const updateMTOCartCacheAfterUpdate = (cache, cartId, {updateMadeToOrderCartItem}) => {
    const clientData = cache.readQuery({
        query: getMadeToOrderCart,
        variables: {cartId: cartId.toLowerCase()}
    });

    const updatedClientData = {
        ...clientData,
        madeToOrderCart: {
            ...clientData.madeToOrderCart,
            madeToOrderCartItems: clientData.madeToOrderCart.madeToOrderCartItems.map((cartItem) =>
                cartItem.cartItemId === updateMadeToOrderCartItem.cartItemId
                    ? {
                          ...cartItem,
                          quantity: updateMadeToOrderCartItem.quantity
                      }
                    : cartItem
            )
        }
    };

    cache.writeQuery({
        data: updatedClientData,
        query: getMadeToOrderCart
    });
};
