import {INVALID_PASSCODE} from '../../enums/passcode-validation-error-messages';
import {
    UPDATE_CART_LOCATION_FAILURE,
    UPDATE_CART_LOCATION_REQUEST,
    UPDATE_CART_LOCATION_SUCCESS
} from '../../action-types';

export const updateCartForSwitchingStores = (router) => (dispatch, getState) => {
    const cart = getState().cart;
    const fulfillmentChangeState = getState().fulfillmentChange;

    return dispatch({
        fetch: {
            onFailure: (dispatchFromMiddleware, getStateFromMiddleware, error) => {
                dispatchFromMiddleware({
                    response: error.response,
                    type: UPDATE_CART_LOCATION_FAILURE,
                    validationErrorMessage: INVALID_PASSCODE
                });
            },
            onSuccess: (dispatchFromMiddleware) => {
                dispatchFromMiddleware({
                    successfullySubmitted: true,
                    type: UPDATE_CART_LOCATION_SUCCESS
                });
            },
            options: {
                body: JSON.stringify({
                    clientPasscode: fulfillmentChangeState.form.inputs.passcode.value,
                    missingCartItems: fulfillmentChangeState.missingCartItems,
                    newLocation: {
                        newDeliveryAddress: fulfillmentChangeState.newDeliveryAddress,
                        newFulfillmentLocationId: router.query.changeFulfillmentLocationTo,
                        newFulfillmentType: fulfillmentChangeState.newFulfillmentType,
                        newPickupLocation: fulfillmentChangeState.newPickupLocation,
                        newStore: fulfillmentChangeState.newStore
                    },
                    oldFulfillmentLocation: {
                        oldFulfillmentStoreId: cart.storeId,
                        oldFulfillmentType: cart.fulfillmentType
                    }
                }),
                method: 'PUT'
            },
            url: `/aisles-online/api/carts/${cart.cartId}/location`
        },
        type: UPDATE_CART_LOCATION_REQUEST
    });
};
