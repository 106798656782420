import React, {useState, useContext, useEffect, createContext, Dispatch, SetStateAction} from 'react';
import {useQuery} from '@apollo/client';

import {getActiveCartForGlobalNavigation_carts} from 'autogen/getActiveCartForGlobalNavigation';
import {GetCustomerData, GetCustomerDataVariables} from 'autogen/GetCustomerData';
import {getCustomerData} from 'client/graphql/queries/customer-and-feature-queries';
import {useUserDetails} from 'client/context/user-details';

import {useGlobalNavigationHeaderCart} from '../utils/global-navigation-helpers';

interface IReservationDrawerContext {
    isOpen: boolean;
    isSelectAPreferredStore: boolean;
    isSelectAPreferredStoreLoading: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    cart: getActiveCartForGlobalNavigation_carts | undefined;
}

const ReservationDrawerContext = createContext<IReservationDrawerContext>({} as IReservationDrawerContext);

export const useProvideReservationDrawer = () => {
    const [isSelectAPreferredStore, setIsSelectAPreferredStore] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const {data: cart} = useGlobalNavigationHeaderCart();
    const {customerUuid} = useUserDetails();

    const {data, loading} = useQuery<GetCustomerData, GetCustomerDataVariables>(getCustomerData, {
        skip: !customerUuid,
        variables: {
            customerUuid: customerUuid || ''
        }
    });

    useEffect(() => {
        if (data?.customer?.preferredStoreId) {
            setIsSelectAPreferredStore(false);
        } else {
            setIsSelectAPreferredStore(true);
        }
    }, [data]);

    return {
        cart,
        isOpen,
        isSelectAPreferredStore,
        isSelectAPreferredStoreLoading: loading,
        setIsOpen
    };
};

export const ReservationDrawerProvider = ({children}) => {
    const drawerContext = useProvideReservationDrawer();

    return <ReservationDrawerContext.Provider value={drawerContext}>{children}</ReservationDrawerContext.Provider>;
};

export const useReservationDrawer = (): IReservationDrawerContext => {
    return useContext(ReservationDrawerContext);
};
