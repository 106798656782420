export const CART_PATH = '/shop/checkout/cart.aspx';
export const NEW_CART_PATH = '/aisles-online/checkout/cart';
export const ERR_404_PATH = '/aisles-online/error';
export const GROCERY_OPTIONS_PATH = '/aisles-online/checkout/grocery-options';
export const CHECKOUT_SUBSTITUTIONS_PATH = '/aisles-online/checkout/substitutions';
export const ACCOUNT_ADDRESSES_PATH = '/my-account/addresses';
export const FUEL_SAVER_PATH = '/my-account/fuel-saver-plus-perks';
export const MEMBERSHIP_SPLASH_PATH = '/plus';
export const MEMBERSHIP_PAYMENT_PATH = '/plus/payment';
export const H_PLUS_PREFERENCES_PATH = '/plus/preferences';
export const GROCERY_PATH = '/grocery';
export const SEARCH_PATH = '/aisles-online/search';
export const STORE_FINDER_PATH = '/stores';
export const PAYMENT_PREFERENCES_PATH = '/aisles-online/preferences/payment';
export const GROCERY_OPTIONS_PATH_WITH_NO_TIME_RESERVATION_MESSAGE = `${GROCERY_OPTIONS_PATH}?m=1`;
export const GROCERY_OPTIONS_PATH_WITH_INVALID_DELIVERY_ADDRESS_MESSAGE = `${GROCERY_OPTIONS_PATH}?m=5`;
export const GROCERY_OPTIONS_PATH_WITH_INVALID_STORE_MESSAGE = `${GROCERY_OPTIONS_PATH}?m=2`;
export const LISTS_PATH = '/aisles-online/lists';
export const SEARCH_ON_SALE_PATH = `${SEARCH_PATH}?searchFilterOptions=ON_SALE%3AON_SALE`;
export const SEARCH_FUEL_SAVER_PATH = `${SEARCH_PATH}?searchFilterOptions=FUEL_SAVER%3AFUEL_SAVER`;
export const PAYMENT_PATH = '/shop/checkout/payment.aspx';
export const ALTERNATIVE_PAYMENT_PATH = '/aisles-online/checkout/payment';

export const EXTERNAL_PATHS = [
    CART_PATH,
    ACCOUNT_ADDRESSES_PATH,
    FUEL_SAVER_PATH,
    MEMBERSHIP_SPLASH_PATH,
    MEMBERSHIP_PAYMENT_PATH,
    H_PLUS_PREFERENCES_PATH,
    GROCERY_PATH,
    STORE_FINDER_PATH,
    PAYMENT_PATH
];
