import {gql} from '@apollo/client';

export const deleteMTOCartItemMutation = gql`
    mutation DeleteMTOCartItemMutation($cartItemId: Int!, $cartId: String!) {
        deleteMadeToOrderCartItem(cartItemId: $cartItemId, cartId: $cartId)
    }
`;
export const updateMTOCartItemMutation = gql`
    mutation UpdateMTOCartItemMutation($updateMadeToOrderCartItemInput: updateMadeToOrderCartItemInput!) {
        updateMadeToOrderCartItem(updateMadeToOrderCartItemInput: $updateMadeToOrderCartItemInput) {
            cartItemId
            quantity
        }
    }
`;
