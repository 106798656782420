import {SET_STORE_SELECTION_TYPE_TAB} from '../../action-types';

export const setStoreSelectionTypeTab = (storeSelectionTypeTab) => (dispatch, getState) => {
    const shouldDisplayNewAddressForm = !getState().storeSelection.deliveryAddresses.length;

    dispatch({
        saveAddressErrorStatus: null,
        shouldDisplayNewAddressForm,
        storeSelectionTypeTab,
        type: SET_STORE_SELECTION_TYPE_TAB
    });
};
