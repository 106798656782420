import {
    SET_SAVE_NEW_DELIVERY_ADDRESS_SUCCESS,
    SET_SAVE_NEW_DELIVERY_ADDRESS_REQUEST,
    SET_SAVE_NEW_DELIVERY_ADDRESS_FAILURE
} from '../../action-types';

export const postNewCustomerDeliveryAddress = (address) => (dispatch, getState) => {
    const customer = getState().customer;
    const isMto = getState().storeSelection.isMto;

    return dispatch({
        fetch: {
            onFailure: (dispatchFromMiddleware, getStateFromMiddleware, error) => {
                error.response.json().then((response) =>
                    dispatchFromMiddleware({
                        saveAddressErrorStatus: response.statusCode,
                        type: SET_SAVE_NEW_DELIVERY_ADDRESS_FAILURE
                    })
                );
            },
            onSuccess: (dispatchFromMiddleware, getStateFromMiddleware, data) => {
                dispatchFromMiddleware({
                    type: SET_SAVE_NEW_DELIVERY_ADDRESS_SUCCESS,
                    ...data
                });
            },
            options: {
                body: JSON.stringify({
                    address
                }),
                method: 'POST'
            },
            url: `/aisles-online/api/customer/${customer.customerId}/delivery-address${isMto ? '?isMto=true' : ''}`
        },
        type: SET_SAVE_NEW_DELIVERY_ADDRESS_REQUEST
    });
};
