import {FOUND} from 'http-status';
import Router from 'next/router';

import {EXTERNAL_PATHS} from '../enums/rerouting-paths';

export const redirectToPath = (path, res) => {
    if (res) {
        res.writeHead(FOUND, {
            Location: path
        });
        res.end();
    } else if (EXTERNAL_PATHS.some((externalPath) => path.startsWith(externalPath))) {
        location.href = path;
    } else {
        Router.replace(path.replace(/^(\/aisles-online)/u, ''));
    }
};
