import {FC, PropsWithChildren, useEffect, useState} from 'react';
import Head from 'next/head';
import useSWR from 'swr';

import {swrFetch as fetcher} from 'client/services/swr-service';

import UserDetailsContext from './context';
import {IUserDetails, IUserDetailsData} from './types';

export const userDetailsUrl = '/aisles-online/api/user-details';

const defaultDetails: IUserDetailsData = {
    isAuthenticated: false
};

export interface IUserDetailsProviderProps {
    fallback?: {
        [userDetailsUrl]: IUserDetailsData;
    };
}

const UserDetailsProvider: FC<PropsWithChildren<PropsWithChildren<IUserDetailsProviderProps>>> = ({
    children,
    fallback
}) => {
    const swrOptions = fallback ? {fallbackData: fallback[userDetailsUrl]} : undefined;
    const {data, error: dataError, mutate} = useSWR<IUserDetailsData, Error>(userDetailsUrl, fetcher, swrOptions);
    const [loading, setLoading] = useState(!swrOptions);
    const [error, setError] = useState<Error | undefined>(undefined);
    const [userDetails, setUserDetails] = useState<IUserDetailsData>(defaultDetails);

    useEffect(() => {
        if (dataError && dataError !== error) {
            setLoading(false);
            setError(dataError);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataError]);

    useEffect(() => {
        if (data && data !== userDetails) {
            setLoading(false);
            setUserDetails(data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const updateUserDetails = async (userDetailsToUpdate?: Partial<IUserDetailsData>) => {
        await mutate({
            ...userDetails,
            ...userDetailsToUpdate
        });
    };

    const contextValue: IUserDetails = {
        ...userDetails,
        error,
        loading,
        updateActiveCartId: async (activeCartId: number) => updateUserDetails({activeCartId}),
        updateCustomerId: async (customerId: number) => updateUserDetails({customerId}),
        updateCustomerUuid: async (customerUuid: string) => updateUserDetails({customerUuid}),
        updateStoreId: async (storeId: number) => updateUserDetails({storeId}),
        updateUserDetails
    };

    return (
        <>
            <Head>
                <link
                    as="fetch"
                    crossOrigin="anonymous"
                    data-testid="user-details-preload"
                    href={userDetailsUrl}
                    key="user-details-preload"
                    rel="preload"
                />
            </Head>
            <UserDetailsContext.Provider value={contextValue}>{children}</UserDetailsContext.Provider>
        </>
    );
};

export default UserDetailsProvider;
