import {
    ADD_GIFT_CARD_FAILURE,
    ADD_GIFT_CARD_REQUEST,
    ADD_GIFT_CARD_SUCCESS,
    EDIT_GIFT_CARD_NICKNAME_FAILURE,
    EDIT_GIFT_CARD_NICKNAME_REQUEST,
    EDIT_GIFT_CARD_NICKNAME_SUCCESS,
    DELETE_GIFT_CARD_FAILURE,
    DELETE_GIFT_CARD_REQUEST,
    DELETE_GIFT_CARD_SUCCESS,
    SET_GIFT_CARD_ERROR,
    SET_GIFT_CARDS_FAILURE,
    SET_GIFT_CARDS_REQUEST,
    SET_GIFT_CARDS_SUCCESS
} from '../../action-types';
import {postMessageToIframeParent} from '../../utils/iframe-helpers';

import {getErrorMessage} from './utils';

export const loadGiftCards = () => ({
    fetch: {
        onFailure: (dispatch) => {
            dispatch({
                loadGiftCardsError: true,
                type: SET_GIFT_CARDS_FAILURE
            });
        },
        onSuccess: (dispatch, getState, data) => {
            dispatch({
                type: SET_GIFT_CARDS_SUCCESS,
                ...data
            });
        },
        url: '/aisles-online/api/gift-cards'
    },
    type: SET_GIFT_CARDS_REQUEST
});

export const setGiftCardError = (error) => {
    return {
        giftCardError: error,
        type: SET_GIFT_CARD_ERROR
    };
};

export const addCustomerGiftCard = (values, toggleGiftCardForm) => (dispatch, getState) => {
    if (!values.giftCardNumber || !values.giftCardCode) {
        return dispatch({
            giftCardError: 'Please enter the gift card number and security code.',
            type: SET_GIFT_CARD_ERROR
        });
    }

    const {customerId} = getState().customer;

    return dispatch({
        fetch: {
            onFailure: (dispatchFromMiddleware, getStateFromMiddleware, error) => {
                error.response.json().then((response) => {
                    dispatchFromMiddleware({
                        giftCardError: getErrorMessage(
                            response,
                            'There was an error adding your gift card. Please try again.'
                        ),
                        type: ADD_GIFT_CARD_FAILURE
                    });
                });
            },
            onSuccess: (dispatchFromMiddleware, getStateFromMiddleware, data) => {
                dispatchFromMiddleware({
                    type: ADD_GIFT_CARD_SUCCESS,
                    ...data
                });
                dispatchFromMiddleware({
                    giftCardError: '',
                    type: SET_GIFT_CARD_ERROR
                });
                toggleGiftCardForm();
            },
            options: {
                body: JSON.stringify(values),
                method: 'POST'
            },
            url: `/aisles-online/api/customer/${customerId}/gift-card`
        },
        type: ADD_GIFT_CARD_REQUEST
    });
};

export const editCustomerGiftCardNickname = (giftCardId, paymentNickname, handleSuccess) => (dispatch, getState) => {
    const {customerId} = getState().customer;

    return dispatch({
        fetch: {
            onFailure: (dispatchFromMiddleware, getStateFromMiddleware, error) => {
                error.response.json().then((response) =>
                    dispatchFromMiddleware({
                        giftCardError: getErrorMessage(
                            response,
                            'There was an error updating your gift card. Please try again.'
                        ),
                        type: EDIT_GIFT_CARD_NICKNAME_FAILURE
                    })
                );
            },
            onSuccess: (dispatchFromMiddleware, getStateFromMiddleware, data) => {
                dispatchFromMiddleware({
                    type: EDIT_GIFT_CARD_NICKNAME_SUCCESS,
                    ...data
                });
                dispatchFromMiddleware({
                    giftCardError: '',
                    type: SET_GIFT_CARD_ERROR
                });
                handleSuccess();
            },
            options: {
                body: JSON.stringify({paymentNickname}),
                method: 'PUT'
            },
            url: `/aisles-online/api/customer/${customerId}/gift-card/${giftCardId}`
        },
        type: EDIT_GIFT_CARD_NICKNAME_REQUEST
    });
};

export const deleteCustomerGiftCard = (giftCardId, toggleGiftCardModal) => (dispatch, getState) => {
    const {customerId} = getState().customer;

    return dispatch({
        fetch: {
            onFailure: (dispatchFromMiddleware) => {
                dispatchFromMiddleware({
                    giftCardError: 'There was an error removing your gift card. Please try again.',
                    type: DELETE_GIFT_CARD_FAILURE
                });
            },
            onSuccess: (dispatchFromMiddleware, getStateFromMiddleware, data) => {
                dispatchFromMiddleware({
                    type: DELETE_GIFT_CARD_SUCCESS,
                    ...data
                });
                dispatchFromMiddleware({
                    giftCardError: '',
                    type: SET_GIFT_CARD_ERROR
                });

                if (toggleGiftCardModal) {
                    toggleGiftCardModal();
                }

                postMessageToIframeParent('gift-card-deleted');
            },
            options: {
                method: 'DELETE'
            },
            url: `/aisles-online/api/customer/${customerId}/gift-card/${giftCardId}`
        },
        type: DELETE_GIFT_CARD_REQUEST
    });
};
