import {NextRouter, useRouter} from 'next/router';

interface IAislesOnlineRouter extends NextRouter {
    asPathWithBasePath: string;
    pathnameWithBasePath: string;
}

export const useAislesOnlineRouter = (): IAislesOnlineRouter => {
    const router = useRouter();

    return {
        ...router,
        asPathWithBasePath: `${router.basePath}${router.asPath}`,
        pathnameWithBasePath: `${router.basePath}${router.pathname}`
    };
};
