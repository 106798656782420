import {SELECTED_CARD_TYPES} from 'client/enums/selected-card-default-types';

import {SET_SELECTED_CUSTOMER_CARD} from '../../action-types';

export const setSelectedCardId = (cardId = null) => ({
    selectedCustomerCardId: cardId,
    type: SET_SELECTED_CUSTOMER_CARD
});

export const resetSelectedCard =
    (cardId = null, useSessionValue = false, updateSession = true) =>
    (dispatch, getState) => {
        if (cardId) {
            if (updateSession) sessionStorage.setItem('selectedCardId', cardId);

            return dispatch({
                selectedCustomerCardId: cardId,
                type: SET_SELECTED_CUSTOMER_CARD
            });
        }

        if (useSessionValue && sessionStorage.selectedCardId) {
            return dispatch({
                selectedCustomerCardId: sessionStorage.selectedCardId,
                type: SET_SELECTED_CUSTOMER_CARD
            });
        }

        const paymentMethods = getState().paymentMethods.paymentMethods;

        if (!paymentMethods.length) {
            if (updateSession) sessionStorage.setItem('selectedCardId', SELECTED_CARD_TYPES.ADD_NEW_CARD);

            return dispatch({
                selectedCustomerCardId: SELECTED_CARD_TYPES.ADD_NEW_CARD,
                type: SET_SELECTED_CUSTOMER_CARD
            });
        }

        const hasDefaultCard = paymentMethods?.find((card) => card.defaultPaymentMethod === true);

        if (hasDefaultCard) {
            if (updateSession) sessionStorage.setItem('selectedCardId', hasDefaultCard?.customerCardId);

            return dispatch({
                selectedCustomerCardId: hasDefaultCard?.customerCardId,
                type: SET_SELECTED_CUSTOMER_CARD
            });
        }

        if (updateSession) sessionStorage.setItem('selectedCardId', paymentMethods?.[0]?.customerCardId);

        return dispatch({
            selectedCustomerCardId: paymentMethods?.[0]?.customerCardId,
            type: SET_SELECTED_CUSTOMER_CARD
        });
    };
