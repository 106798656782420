import {Action, ActionCreator} from 'redux';

import {CLEAR_PLACE_ORDER_ERROR_STATUS, PLACE_ORDER_FAILURE} from '../../action-types';

export const clearPlaceOrderErrorStatus: ActionCreator<Action & {placeOrderErrorStatus: string | null}> = () => ({
    placeOrderErrorStatus: null,
    type: CLEAR_PLACE_ORDER_ERROR_STATUS
});

export const setPlaceOrderStatus: ActionCreator<Action & {placeOrderErrorStatus: string | null}> = (
    message: string
) => ({
    orderInProgress: false,
    placeOrderErrorStatus: message,
    type: PLACE_ORDER_FAILURE
});
