import {useState, useEffect} from 'react';

export const useLocalStorage = (key: string, initialValue: any = '') => {
    // Get the initial value from localStorage or use the provided initial value
    const [storedValue, setStoredValue] = useState(() => {
        try {
            const item = window.localStorage.getItem(key);

            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            // console.log(error);

            return initialValue;
        }
    });

    // Update localStorage when the stored value changes
    useEffect(() => {
        try {
            window.localStorage.setItem(key, JSON.stringify(storedValue));
        } catch (error) {
            // console.log(error);
        }
    }, [key, storedValue]);

    return [storedValue, setStoredValue];
};
