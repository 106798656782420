import Cookies from 'js-cookie';

import {REMOVE_CART_ITEMS} from '../../action-types';
import {deleteCartItemAction} from '../../graphql/mutations/mutation-actions/cart-mutation-actions';
import {deleteMTOCartItemAction} from '../../graphql/mutations/mutation-actions/mto-mutation-actions';

export const removeCartItems = ({customerId, groceryCartItemIds, madeToOrderCartItemIds}) => {
    groceryCartItemIds.forEach((cartItemId) => {
        deleteCartItemAction(cartItemId, customerId);
    });

    const cartId = Cookies.get('ecomCartUUID');

    madeToOrderCartItemIds.forEach((cartItemId) => {
        deleteMTOCartItemAction(cartId, cartItemId);
    });

    return {
        groceryCartItemIds,
        madeToOrderCartItemIds,
        type: REMOVE_CART_ITEMS
    };
};
